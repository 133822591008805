<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch" class="image-container" *ngIf="image.image">
    <img [id]="small ? imageID : ''" [src]="image.image.url" style="width: 100%;">
    <markdown *ngIf="noModal" [data]="image.title" class="figure-title"></markdown>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" *ngIf="noModal && showActions">
        <div fxFlex="0 0 auto" (click)="showModal(image)" class="action-button">
            <i nz-icon nzType="fullscreen" nzTheme="outline"></i>
            Full Size
        </div>
        <div fxFlex="0 0 auto" (click)="downloadPNG(image.image.url)" class="action-button">
            <i nz-icon nzType="download" nzTheme="outline"></i>
            Download PNG
        </div>
    </div>
</div>

<ng-template #modalFullSizeView>
    <app-image fxFlex="1 1 auto" [image]="image" [noModal]="false" [small]="false"></app-image>
</ng-template>
