<ng-container *ngIf="table && table.headers; else noHeadersTemplate">
  <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="2px" class="table-container" [ngClass]="{'small': small}">
    <table [id]="tableID" fxFlex="0 0 auto">
      <caption *ngIf="noModal">Table {{table.tableNumber}}: {{table.name}}</caption>
      <thead>
        <tr>
          <th *ngFor="let header of table.headers">{{header}}</th>
        </tr>
      </thead>
      <tbody *ngIf="filteredData$ | async as data">
        <ng-container *ngIf="table.groups; else rowsTemplate">
          <ng-container *ngFor="let group of table.groups">
            <ng-container *ngIf="table.subgroups; else groupTemplate">
              <tr class="group" *ngIf="groupNotEmpty(data, group)">
                <td [attr.colspan]="table.headers.length">
                  {{group}}
                </td>
              </tr>
              <ng-container *ngFor="let subgroup of table.subgroups[group]">
                <tr class="subgroup" *ngIf="groupNotEmpty(data, group, subgroup)">
                  <td [attr.colspan]="table.headers.length">
                    {{subgroup}}
                  </td>
                </tr>
                <ng-container *ngTemplateOutlet="rowsTemplate; context: {group: group, subgroup: subgroup}">
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-template #groupTemplate>
              <tr class="group" *ngIf="groupNotEmpty(data, group)">
                <td [attr.colspan]="table.headers.length">
                  {{group}}
                </td>
              </tr>
              <ng-container *ngTemplateOutlet="rowsTemplate; context: {group: group}"></ng-container>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-template #rowsTemplate let-group="group" let-subgroup="subgroup">
          <ng-container *ngFor="let row of data">
            <tr *ngIf="rowIsVisible(row, group, subgroup)" [ngClass]="{subRow: !!subgroup}">
              <td *ngFor="let field of table.headers">
                <markdown *ngIf="row[field]" [data]="row[field]" (click)="ui.onMarkdownClick($event);">
                </markdown>
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </tbody>
    </table>
    <markdown fxFlex="0 0 auto" *ngIf="table.footnotes" [data]="table.footnotes" (click)="ui.onMarkdownClick($event);"></markdown>
  </div>
</ng-container>
<ng-template #noHeadersTemplate>
  loading...
</ng-template>
