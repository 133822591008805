<div fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill>
  <app-topbar fxFlex="0 0 auto" [id]="reportId$ | async" (save)="showModal = true" (update)="update($event)"
    (cancel)="cancelUpdate()" [reportTitle]="(customReport$ | async)?.report?.title">
  </app-topbar>
  <div fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="space-between stretch" class="content">
    <app-contents fxFlex="0 0 250px" [filteredReport$]="filteredReport$" (sectionChange)="updateCurrentSection($event)">
    </app-contents>
    <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch" style="overflow: auto;">
      <app-filters [selectedFilters$]="selectedFilters$" [activeFilters$]="filters$"
        [loadingData]="loadingData$ | async" (filtersUpdated)="selectedFiltersSource.next($event)"
        (filtersApplied)="applyFilters()" fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start center"
        *ngIf="ui.filtersStatus==='out'"></app-filters>
      <app-report [report$]="report$" [references$]="references$" [activeFilters$]="filters$"
        (currentSectionChange)="updateCurrentSection($event)" fxFlex="1 1 100%" fxLayout="column"
        fxLayoutAlign="start center" fxLayoutAlign.lt-lg="start start" [showActions]="true"></app-report>
    </div>
    <app-figures fxFlex="0 0 350px" [filteredReport$]="filteredReport$" [references$]="references$"
      [activeFilters$]="filters$"></app-figures>
  </div>
</div>

<!-- Save report modal -->
<nz-modal [(nzVisible)]="showModal" nzTitle="Modal Title" (nzOnCancel)="cancelSave()" (nzOnOk)="saveReport()"
  [nzOkLoading]="modalSaving" [nzCancelDisabled]="modalSaving" [nzOkText]="'Save'" [nzOkDisabled]="!reportName"
  [nzTitle]="'Save report'">
  <input nz-input placeholder="Report name" [(ngModel)]="reportName" />
</nz-modal>