import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql/graphql.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ValidationDirective } from './app.validation.directive';

import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {
  UserOutline,
  PlusCircleOutline,
  FilterOutline,
  FundOutline,
  TableOutline,
  FileImageOutline,
  SnippetsOutline,
  BookOutline,
  AimOutline,
  GlobalOutline,
  MedicineBoxOutline,
  FolderOpenOutline,
  ProfileOutline,
  LinkOutline,
  DownloadOutline,
  FilePdfFill,
  FileWordFill,
  LockOutline,
  FullscreenOutline,
  CheckOutline,
  FolderOutline
} from '@ant-design/icons-angular/icons';
const icons: IconDefinition[] = [
  UserOutline,
  PlusCircleOutline,
  FilterOutline,
  FundOutline,
  TableOutline,
  FileImageOutline,
  SnippetsOutline,
  BookOutline,
  AimOutline,
  GlobalOutline,
  MedicineBoxOutline,
  FolderOpenOutline,
  ProfileOutline,
  LinkOutline,
  DownloadOutline,
  FilePdfFill,
  FileWordFill,
  LockOutline,
  FullscreenOutline,
  CheckOutline,
  FolderOutline
];

import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';

import { NzSelectModule } from 'ng-zorro-antd/select';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { LoginComponent } from './login/login.component';
import { EditorComponent } from './editor/editor.component';
import { ReportComponent } from './report/report.component';
import { MarkdownModule, MarkedRenderer, MarkedOptions } from 'ngx-markdown';
import { FiltersComponent } from './filters/filters.component';
import { TopbarComponent } from './topbar/topbar.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { ContentsComponent } from './contents/contents.component';
import { ChartComponent } from './chart/chart.component';
import { FiguresComponent } from './figures/figures.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { TableComponent } from './table/table.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

import { ReportListComponent } from './report-list/report-list.component';
import { MatomoModule } from 'ngx-matomo';

import { InViewportModule } from '@thisissoon/angular-inviewport';
import { StyledTableComponent } from './styled-table/styled-table.component';
import { ReferenceComponent } from './reference/reference.component';
import { environment } from '../environments/environment';
import { release } from '../../../release';
import * as Sentry from '@sentry/browser';
import { SupplementaryComponent } from './supplementary/supplementary.component';
import { ImageComponent } from './image/image.component';
import { ViewerComponent } from './viewer/viewer.component';

if (environment.environment !== 'localhost') {
  Sentry.init({
    dsn: 'https://071a683c0b1543438230abc95d6890ce@errors.spintechit.ca/28',
    environment: environment.environment,
    blacklistUrls: ['localhost:4200'],
    release
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  // tslint:disable-next-line: no-any
  public handleError(error: any): void {
    Sentry.captureException(error.originalError || error);
  }
}

export class MarkdownRenderer extends MarkedRenderer {
  public link(href: string | null, title: string | null, text: string): string {
    return `<a href="${window.location.href}/${href}">${text}</a>`;
  }
}

function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkdownRenderer();
  return {
    renderer
  };
}

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EditorComponent,
    ValidationDirective,
    ReportComponent,
    FiltersComponent,
    ReportComponent,
    FiltersComponent,
    EditorComponent,
    TopbarComponent,
    ContentsComponent,
    ChartComponent,
    FiguresComponent,
    TableComponent,
    ReportListComponent,
    StyledTableComponent,
    ReferenceComponent,
    SupplementaryComponent,
    ImageComponent,
    ViewerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GraphQLModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzFormModule,
    NzSwitchModule,
    NzInputModule,
    NzNotificationModule,
    NzButtonModule,
    NzCheckboxModule,
    NzToolTipModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    NzSpinModule,
    NzIconModule.forRoot(icons),
    NzDropDownModule,
    NzModalModule,
    NzMessageModule,
    NgxEchartsModule.forRoot({
      echarts
    }),
    NzTabsModule,
    InViewportModule,
    NzSelectModule,
    MatomoModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: ErrorHandler, useClass: environment.environment === 'localhost' ? ErrorHandler : SentryErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
