import { EChartOption } from 'echarts';
import { TableRow, NumberedChart, Filters } from '../types';
import { filterData } from '../helpers';

const iconColor = '#008579';
const transparentColor = 'rgba(0,0,0,0)';
const fontLine = '#404040';
const bgLine = '#ffffff';

const commonChartSettings: EChartOption = {
    textStyle: {
        fontFamily: 'Helvetica Neue'
    },
    color: [iconColor, '#632B86', '#94D60A', '#575756', '#004750', '#60C3D6'],
    animationDuration: 0,
    animationDurationUpdate: 0,
    grid: {
        containLabel: true,
        top: 20,
        bottom: 20,
        right: 20,
        left: 20,
    },
};

interface ForestEntry {
    'Author (year)': string;
    Histology: string;
    pCR: string;
    'No pCR': string;
    Stage: string;
    'HR source': string;
    'Total N': number;
    n: number;
    HR: number;
    'L-95': number;
    'U-95': number;
    '% with pCR/MPR': number;
    'Total resected population': number;
}

interface LinkEntry {
    source: string;
    target: string;
    value: string;
}

interface NodeEntry {
    name: string;
    x: number;
    y: number;
    itemStyle: object;
}
interface PieHeaders {
    titles: string[];
    center: {
        [key in string]: {
            x: string,
            y: string
        }
    };
    chartHeight: string;
}

interface BarChartConfigData {
    maxXAxisValue: number;
    xAxisTitle: string;
    xAxisFormatter?: string;
    barsColor: string;
    chartHeight: number;
    leftGridPercentage: string;
    yAxisName?: string;
    series?: string[];
    colors?: object;
    barWidth?: number;
    reverse?: boolean;
    yAxisFontSize?: number;
}

const getBarChartOptions = (data: TableRow[], config: BarChartConfigData): EChartOption<EChartOption.SeriesBar> => {

    const yAxisLabels = [];
    const percentages = [];
    const titles = [];

    Object.keys(data).forEach((key: string): void => {

        titles.push(key);

        yAxisLabels.push('', key, ''); // make some spacing (3 bar equivalent) for the titles
        percentages.push(null, null, null);

        yAxisLabels.push(...data[key].map((dataPoint: { xAxisLabel: string, value: string }): string => dataPoint.xAxisLabel));
        percentages.push(...data[key].map((dataPoint: { xAxisLabel: string, value: string }): number => Number(dataPoint.value)));

    });

    const options: EChartOption<EChartOption.SeriesBar> = {
        yAxis: {
            type: 'category',
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            data: yAxisLabels.reverse(),
            axisLabel: {
                // tslint:disable-next-line: object-literal-shorthand only-arrow-functions
                formatter: function(value: string): string {
                    return value.toLocaleUpperCase() === value ? '{sectionTitle|' + value + '}' : value;
                },
                align: 'right',
                rich: {
                    sectionTitle: {
                        fontWeight: 'bold',
                        fontSize: 18,
                        align: 'left',
                        width: 100,
                        padding: [0, 30, 0, 0]
                    },
                }
            }
        },
        xAxis: {
            type: 'value',
            max: Number(config.maxXAxisValue),
            position: 'top',
            axisLine: { show: false },
            axisTick: { show: false },
        },
        title: {
            text: config.xAxisTitle,
            textStyle: {
                fontWeight: 'normal',
                fontSize: 15
            },
            right: '20%'
        },
        grid: [
            { left: config.leftGridPercentage },
        ],
        series: [{
            type: 'bar',
            barCategoryGap: '40%',
            cursor: 'auto',
            label: {
                position: 'right',
                show: true,
                color: '#000'
            },
            itemStyle: {
                color: config.barsColor,
                // shadowBlur: 5,
                // shadowOffsetY: 1,
                // shadowOffsetX: 1
            },
            emphasis: {
                itemStyle: {
                    color: config.barsColor,
                    // shadowBlur: 5,
                    // shadowOffsetY: 1,
                    // shadowOffsetX: 1
                }
            },
            data: percentages.reverse(),
        }]
    };

    return options;
};


const getStackedBarChartOptions = (data: TableRow[], config: BarChartConfigData): EChartOption<EChartOption.SeriesBar> => {
    const seriesObj: { [key in string]: number[] } = {};
    config.series.forEach((seriesName: string): undefined[] => seriesObj[seriesName] = []);
    const yFieldName = config.yAxisName;

    const categories: EChartOption.YAxis['data'] = [];
    if (data) {
        data.forEach((point: TableRow): void => {
            categories.push(point[yFieldName] as string);
            Object.keys(seriesObj).forEach((seriesName: string): number => seriesObj[seriesName].push(point[seriesName] as number));
        });
    }

    if (config.reverse) {
        categories.reverse();
    }

    const barWidth = config.barWidth;
    const series: EChartOption.SeriesBar[] = [];
    for (const seriesName in seriesObj) {
        if (seriesObj.hasOwnProperty(seriesName)) {
            series.push({
                name: seriesName,
                type: 'bar',
                stack: 'a',
                cursor: 'auto',
                data: config.reverse ? seriesObj[seriesName].reverse() : seriesObj[seriesName],
                barWidth,
                itemStyle: {
                    color: config.colors[seriesName]
                },
                emphasis: {
                    itemStyle: {
                        color: config.colors[seriesName]
                    }
                },
            });
        }
    }
    const fontSize = config.yAxisFontSize;
    let axisLabel = {
      // tslint:disable-next-line: object-literal-shorthand only-arrow-functions
        formatter: function(value: string): string {
          return value;
        }
    };
    if (config.xAxisFormatter === '%') {
      axisLabel = {
        // tslint:disable-next-line: object-literal-shorthand only-arrow-functions
          formatter: function(value: string): string {
            return value + '%';
          }
      };
    }
    return {
        grid: {
            containLabel: true,
            top: 20,
            bottom: 50,
            right: 15,
            left: 30
        },
        legend: {
            data: series,
            bottom: 0,
            itemWidth: 10,
            itemHeight: 10,

        },
        xAxis: {
            type: 'value',
            max: config.maxXAxisValue,
            axisLabel
        },
        yAxis: {
            type: 'category',
            data: categories,
            axisLabel: {
                fontSize
            },
            axisTick: { show: false },
            axisLine: { show: false },
        },
        series
    };
};

const getForestPlot = (data: { [key in string]: ForestEntry[] }, height: string): EChartOption => {
    const options: EChartOption = {
        ...commonChartSettings,
        tooltip: {
            trigger: 'none'
        },
        title: {
            text: 'Author (year)         Histology         Stage           pCR     No pCR                                OS by pCR                              HR      95%-CI',
            textStyle: {
                fontSize: 12,
                fontWeight: 'bold',
                padding: [10, 0]
            }
        },
        grid: {
            ...commonChartSettings.grid,
            containLabel: false,
            bottom: 30 + ((commonChartSettings.grid as EChartOption.Grid).bottom as number),
            left: 360,
            width: 250,
            top: 30,
            height: Number(height) - 30
        },
        xAxis: {
            show: true,
            name: '    favours pCR      favours No pCR',
            nameLocation: 'center',
            nameGap: 30,
            axisLine: {
                show: true
            },
            splitArea: {
                show: false
            },
            min: 0.01,
            max: 100,
            type: 'log',
            position: 'bottom',
            splitLine: { lineStyle: { type: 'dashed' } },
        },
        yAxis: {
            type: 'category',
            axisLine: {
                show: false
            },
            axisLabel: {
                show: true,
                rich: {
                    group: {
                        color: 'gray',
                        width: 350,
                        align: 'left'
                    },
                    author: {
                        align: 'left',
                        width: 110,
                    },
                    histology: {
                        align: 'center',
                        width: 50
                    },
                    stage: {
                        align: 'center',
                        width: 90
                    },
                    pCR: {
                        align: 'center',
                        width: 50
                    },
                    nopCR: {
                        align: 'center',
                        width: 50
                    },
                }
            },
            axisTick: { show: false },
            splitLine: { show: false },
            inverse: true,
            data: []
        },
        series: [
            {
                name: 'HR and 95%-CI',
                type: 'bar',
                silent: true,
                label: {
                    normal: {
                        show: true,
                        // tslint:disable-next-line: object-literal-shorthand only-arrow-functions
                        formatter: function(item: { data: { value: number, label: string } }): string {
                            return item.data.label;
                        },
                        position: 'right',
                        color: iconColor
                    },
                    emphasis: {
                        show: true,
                        position: 'right',
                        color: iconColor
                    }
                },
                itemStyle: {
                    normal: {
                        barBorderColor: transparentColor,
                        color: transparentColor
                    },
                    emphasis: {
                        barBorderColor: transparentColor,
                        color: transparentColor
                    }
                },
                data: []
            },
            {
                name: 'Population size',
                type: 'scatter',
                symbol: 'rect',
                silent: true,
                label: {
                    normal: {
                        show: false
                    },
                    emphasis: {
                        show: false
                    }
                },
                itemStyle: {
                    normal: {
                        color: '#B7B7B7',
                    },
                },
                data: []
            },
            {
                name: 'HR',
                type: 'scatter',
                symbol: 'diamond',
                silent: true,
                symbolSize: 6,
                label: {
                    normal: {
                        show: false
                    },
                    emphasis: {
                        show: false
                    }
                },
                itemStyle: {
                    normal: {
                        color: iconColor,
                    },
                },
                data: []
            },
            {
                name: 'CI range',
                type: 'boxplot',
                silent: true,
                boxWidth: [1],
                itemStyle: {
                    borderColor: iconColor,
                    borderWidth: 2
                },
                data: []
            }
        ]
    };

    for (const group in data) {
        if (data.hasOwnProperty(group)) {
            (options.yAxis as EChartOption.YAxis).data.push(`{group|${group}}`);

            options.series[0].data.push(null);
            options.series[1].data.push(null);
            options.series[2].data.push(null);
            (options.series[3].data as number[][]).push([0, 0, 0, 0]);
            data[group].forEach((row: ForestEntry): void => {
                // Y Axis columns
                (options.yAxis as EChartOption.YAxis).data.push(
                    `{author|${row['Author (year)']}}{histology|${row.Histology}}{stage|${row.Stage}}{pCR|${row.pCR}}{nopCR|${row['No pCR']}}`
                );
                // HR & 95%-CI labels
                (options.series[0].data as object[]).push({
                    value: 100 - (row['L-95'] ? row['L-95'] : 0),
                    label: `${row.HR.toFixed(2)}` + (row['L-95'] ? ` [${row['L-95'].toFixed(2)}, ${row['U-95'].toFixed(2)}]` : '')
                });
                // Population size
                (options.series[1].data as object[]).push({
                    value: row.HR,
                    symbolSize: row['Total N'] / 100
                });
                // HR
                (options.series[2].data as number[]).push(row.HR);
                // 95%-CI boxplot
                (options.series[3].data as number[][]).push([row['L-95'], row.HR, row.HR, row.HR, row['U-95']]);
            });

        }
    }
    return options;
};

const getGraphChartOptions = (data: { [key in string]: (LinkEntry | NodeEntry)[] }): EChartOption => {
    const nodes = [];
    const links = [];

    data.nodes.forEach((node: NodeEntry): void => {
        const nodeObj = {
            ...node,
            label: {
                show: true,
                fontSize: 9,
                color: 'black'
            }
        };
        nodes.push(nodeObj);
    });

    data.links.forEach((link: LinkEntry): void => {
        const linkObj = {
            ...link,
            label: {
                show: true,
                // tslint:disable-next-line: object-literal-shorthand only-arrow-functions
                formatter: function(params: {value: string}): string  { return params.value; },
                fontSize: 9,
                color: 'black',
                width: link.value
            }
        };
        links.push(linkObj);
    });

    const options: EChartOption = {
        ...commonChartSettings,
        tooltip: {
            trigger: 'none'
        },
        series: [
            {
                type: 'graph',
                layout: 'none',
                symbolSize: 50,
                roam: false,
                silent: true,
                label: {
                    show: true
                },
                edgeSymbol: ['none', 'none'],
                edgeSymbolSize: [4, 10],
                edgeLabel: {
                    fontSize: 20
                },
                data: nodes,
                links,
            }
        ]
    };

    return options;
};

interface PieChartConfigData {
    chartHeight: string;
    includeLegend: boolean;
}

const getPieChartOptions = (rawData: TableRow[], config: PieChartConfigData): EChartOption => {
    const data = [];
    const legend = [];

    rawData.forEach((entry: TableRow): void => {
        const entryObject = {
            name: entry.name,
            value: entry.value,
            itemStyle: {
                color: entry.color
            },
            label: {
                show: true,
                position: entry.labelPosition,
                color: entry.labelColor
            },
            legendInfo: config.includeLegend
        };
        data.push(entryObject);
        if (config.includeLegend) {
            legend.push(entry.name);
        }
    });

    const options: EChartOption = {
        ...commonChartSettings,
        tooltip: {
            trigger: 'none'
        },
        legend: {
            selectedMode: false,
            bottom: 10,
            left: 'center',
            data: legend
        },
        series: [
            {
                type: 'pie',
                radius: '65%',
                center: ['50%', '50%'],
                selectedMode: false,
                animation: false,
                hoverAnimation: false,
                legendHoverLink: false,
                silent: true,
                cursor: 'default',
                data,
                label: {
                    fontSize: 12,
                    // tslint:disable-next-line: object-literal-shorthand only-arrow-functions
                    formatter: function(params: { data: { value: number, name: string, legendInfo: boolean }}): string {
                        if (params.data.legendInfo) {
                            return `${params.data.value}%`;
                        } else {
                            return `${params.data.name}\n${params.data.value}%`;
                        }
                    }
                }
            }
        ]
    };

    return options;
};

const getMultiPieChartOptions = (rawData: { [key in string]: TableRow[] }, headers: PieHeaders): EChartOption => {
    const allDatasets = [];
    const titleText: string[] = [];
    for (const group in rawData) {
        if (rawData.hasOwnProperty(group)) {
            const data: object[] = [];
            rawData[group].forEach((entry: TableRow): void => {
                const entryObject = {
                    name: entry.name,
                    value: entry.value,
                    itemStyle: {
                        color: entry.color,
                    }
                };
                data.push(entryObject);
            });
            allDatasets.push(data);
            titleText.push(group);
        }
    }

    const seriesData = [];
    const titles = [];
    allDatasets.forEach((data: object[]): void => {
        const seriesTitle = titleText[allDatasets.indexOf(data)];

        if (headers.center.hasOwnProperty(seriesTitle)) {
            const leftMargin = Number(headers.center[seriesTitle].x.slice(0, - 1)) - 14;
            const topMargin = Number(headers.center[seriesTitle].y.slice(0, - 1)) - 21;
            const title = {
                text: seriesTitle,
                left: leftMargin.toString() + '%',
                top: topMargin.toString() + '%',
                textStyle: {
                    fontSize: 14
                }
            };
            titles.push(title);

            const singleSeries = {
                type: 'pie',
                center: [headers.center[seriesTitle].x, headers.center[seriesTitle].y],
                radius: ['20%', '26%'],
                cursor: 'default',
                silent: true,
                data,
                label: {
                    show: false,
                    position: 'center',
                    // tslint:disable-next-line: object-literal-shorthand only-arrow-functions
                    formatter: function(params: { data: { value: number, name: string } }): string {
                        return `${params.data.value}%`;
                    }
                },
                emphasis: {
                    label: {
                        show: true
                    }
                }
            };
            seriesData.push(singleSeries);
        }
    });

    const options: EChartOption = {
        ...commonChartSettings,
        title: titles,
        legend: {
            selectedMode: false,
            bottom: 10,
            left: 'center',
            data: headers.titles
        },
        series: seriesData
    };
    return options;
};
interface LineChartConfigData {
    xAxisTitle: string;
    xAxisValue: string[];
    yAxisTitle: string;
    yAxisValue: number;
    chartHeight: string;
}

const getLineChartOptions = (rawData: TableRow[], config: LineChartConfigData): EChartOption => {
    const series = [];
    const legend = [];
    const color = [];

    rawData.forEach((line: TableRow): void => {
        const lineObject = {
            type: 'line',
            name: line.name,
            data: line.data,
            symbol: 'none',
            silent: true,
            hoverAnimation: false,
            cursor: 'defult',
            lineStyle: {
                color: line.color,
                width: 3,
                type: 'solid'
            }
        };

        series.push(lineObject);
        legend.push(line.name);
        color.push(line.color);
    });

    const options: EChartOption = {
        ...commonChartSettings,
        color,
        backgroundColor: bgLine,
        grid: {
            show: true,
            containLabel: true,
            top: '20%',
            left: '8%',
            right: '2%',
            bottom: '8%'
        },
        title: {
            show: true,
            text: 'SEER stat data 2015',
            textStyle: {
                color: fontLine,
                fontSize: 18
            },
            left: 'center',
            top: 10
        },
        tooltip: {
            trigger: 'none'
        },
        xAxis: {
            type: 'category',
            data: config.xAxisValue,
            name: config.xAxisTitle,
            nameLocation: 'center',
            nameTextStyle: {
                color: fontLine
            },
            nameGap: 50,
            axisLabel: {
                color: fontLine,
                rotate: 45
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: fontLine
                }
            }
        },
        yAxis: {
            type: 'value',
            name: config.yAxisTitle,
            nameLocation: 'center',
            nameRotate: 90,
            nameTextStyle: {
                color: fontLine
            },
            nameGap: 35,
            axisLabel: {
                color: fontLine
            },
            min: 0,
            max: config.yAxisValue,
            splitNumber: 10
        },
        legend: {
            show: true,
            left: 'center',
            top: 50,
            data: legend,
            textStyle: {
                color: fontLine
            }
        },
        series
    };

    return options;
};

export const getChart = (chart: NumberedChart, filters: Filters): EChartOption => {
    if (chart.table && chart.table.headers && chart.table.headers.chartType) {
      switch (chart.table.headers.chartType) {
          case 'pie':
              return getPieChartOptions(chart.table.data, chart.table.headers);

          case 'multiPie':
              return getMultiPieChartOptions(chart.table.data, chart.table.headers);

          case 'bar':
              return getBarChartOptions(chart.table.data, chart.table.headers);

          case 'stackedBar':
              const filterMap = new Map<string, string>(Object.entries(chart.table.filterMap || {}));
              const dataFiltered = filterData(chart.table.data, filters, filterMap);
              return getStackedBarChartOptions(dataFiltered, chart.table.headers);

          case 'line':
              return getLineChartOptions(chart.table.data, chart.table.headers);

          case 'forest':
              return getForestPlot(chart.table.data, chart.table.headers);

          case 'graph':
              return getGraphChartOptions(chart.table.data);

          default:
              console.warn('Unknown chart type:', chart.table.headers.chartType);
      }
    }
    return {};
};
