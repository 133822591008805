import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NumberedStyledTable } from '../types';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-styled-table',
  templateUrl: './styled-table.component.html',
  styleUrls: ['./styled-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StyledTableComponent implements OnInit {
  @Input() public styledTable: NumberedStyledTable;
  @Input() public small = false;
  @Input() public noModal = true;

  public tableID = '';

  constructor(
    public ui: UiService,
  ) { }

  public ngOnInit(): void {
    this.tableID = this.small ? `tab-${this.styledTable.tableNumber}` : '';
  }

}
