import { Filters } from './../types';
import { Table, StyledTable, SectionsQuery, Chart, Image, SupplementariesQuery, SupplementariesGQL } from './../graphql/graphql';
import { Component, ViewChild, TemplateRef, Input, OnInit } from '@angular/core';
import { UiService } from '../ui.service';
import { DownloadService } from '../download.service';
import { NzModalService, NzTabChangeEvent } from 'ng-zorro-antd';
import { TableRow, NumberedSection, NumberedReference } from '../types';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';

type Figures = { charts: Partial<Chart>[], tables: Partial<Table | StyledTable>[], images: Partial<Image>[] };

@Component({
  selector: 'app-figures',
  templateUrl: './figures.component.html',
  styleUrls: ['./figures.component.scss'],
})
export class FiguresComponent implements OnInit {
  @ViewChild('modalFullSizeView', { static: true }) private modalFullSizeView: TemplateRef<{}>;
  public tableModal: Partial<Table> | Partial<StyledTable>;
  public environment = environment;
  @Input() public filteredReport$: Observable<NumberedSection[]>;
  @Input() public references$: Observable<Partial<NumberedReference>[]>;
  @Input() public activeFilters$: Observable<Filters>;

  public figures$: Observable<Figures>;

  public supplementary$ = this.supplementariesGQL.watch().valueChanges.pipe(
    map(({ data }: ApolloQueryResult<SupplementariesQuery>): SupplementariesQuery['supplementaries'] => data.supplementaries)
  );

  public ngOnInit(): void {
    this.figures$ = this.filteredReport$.pipe(
      map(
        (report: SectionsQuery['sections']): Figures => {
          const charts: Partial<Chart>[] = [];
          const tables: Partial<Table | StyledTable>[] = [];
          const images: Partial<Image>[] = [];

          const loopThroughContent = (content: SectionsQuery['sections'][0]['content']): void => {
            content.forEach((contentItem: SectionsQuery['sections'][0]['content'][0]): void => {
              switch (contentItem.__typename) {
                case 'ComponentReportContentChart':
                  charts.push(
                    contentItem.chart as Partial<Chart>
                  );
                  break;
                case 'ComponentReportContentTable':
                  tables.push(
                    contentItem.table as Partial<Table>
                  );
                  break;
                case 'ComponentReportContentStyledTable':
                  tables.push(
                    contentItem.styled_table as Partial<StyledTable>
                  );
                  break;
                case 'ComponentReportContentImage':
                  images.push(
                    contentItem.image as Partial<Image>
                  );
                  break;
              }
            });
          };

          if (report) {
            report.forEach((section: SectionsQuery['sections'][0]): void => {
              loopThroughContent(section.content);
            });
          }

          return { charts, tables, images };
        })
    );
  }

  constructor(
    public ui: UiService,
    private download: DownloadService,
    private modal: NzModalService,
    private supplementariesGQL: SupplementariesGQL,
  ) { }

  public tabIsInView(inView: boolean, tabIndex: number): void {
    if (inView) {
      this.ui.tabIsInView(tabIndex);
    }
  }


  // download TABLE
  public downloadXLSX(table: Partial<Table>): void {
    if (table.name && table.headers.length > 0 && table.data.length > 0) {
      const name = table.name;
      const headers = table.headers;
      const data = table.data;
      this.download.downloadXLSX(name, headers, data);
    } else {
      console.error('Export failed. Some table data is missing.');
    }
  }

  // download STYLED TABLE
  public downloadStyledXLSX(table: Partial<StyledTable>): void {
    if (table.Title && table.contents) {
      const name = table.Title;
      const doc = new DOMParser().parseFromString(table.contents, 'text/xml');
      // headers
      const headerContent = Array.from(doc.getElementsByTagName('th'));
      const headers: string[] = [];
      headerContent.forEach((header: HTMLTableCellElement): void => {
        headers.push(header.innerHTML);
      });
      // body
      // tslint:disable-next-line: no-any
      const body: any = doc.querySelectorAll('tr');
      const data: TableRow[] = [];
      for (const row of body) {
        const rowData = row.querySelectorAll('td');
        if (rowData.length) {
          const tableRow = {};
          for (let i = 0; i < rowData.length; i++) {
            const nodes: NodeList = rowData[i].childNodes;
            tableRow[headers[i]] = '';
            let cell = '';
            nodes.forEach((node: Node): void => {
              const cellContent = node.textContent.trim();
              cell = (cell + cellContent + ' ');
            });
            const cleanCell = cell.replace(/\s/g, ' ').trim();
            tableRow[Object.keys(tableRow)[i]] = cleanCell;
          }
          data.push(tableRow);
        }
      }
      this.download.downloadXLSX(name, headers, data);
    } else {
      console.error('Export failed. Some table data is missing.');
    }
  }

  public showModal(table: Partial<Table> | Partial<StyledTable>): void {
    const name = table.__typename === 'StyledTable' ? table.Title : table.name;
    this.tableModal = table;
    this.modal.create({
      nzTitle: 'Table ' + table.number + ': ' + name,
      nzContent: this.modalFullSizeView,
      nzFooter: null,
      nzStyle: {
        top: '30px',
        width: '100%'
      },
      nzBodyStyle: {
        width: '100%',
        height: '100%'
      }
    });
  }

  public updateTabChange(event: NzTabChangeEvent): void {
    this.ui.updateCurrentTab(event.index);
  }
}
