import { map } from 'rxjs/operators';
import { ReportsGQL, ReportsQuery, DeleteReportMutation, FiltersQuery, FiltersGQL, DeleteReportGQL, ReportsDocument } from './../graphql/graphql';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { AuthService, AuthResult } from '../auth.service';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { FetchResult } from 'apollo-link';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';


@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})

export class ReportListComponent implements OnInit, OnDestroy {
  @ViewChild('shareConfirmTitle', { static: true }) private shareConfirmTitle: TemplateRef<{}>;
  @ViewChild('shareConfirmContent', { static: true }) private shareConfirmContent: TemplateRef<{}>;

  public authorId = '';
  public url = '';

  private authorization: Subscription;
  private allReports$ = this.reports.watch().valueChanges.pipe(
    map(({ data }: ApolloQueryResult<ReportsQuery>): ReportsQuery['reports'] => data.reports)
  );

  public authors$ = this.allReports$.pipe(
    map((reports: ReportsQuery['reports']): ReportsQuery['reports'][0]['author'][] => {
      const authors = reports.map((report: ReportsQuery['reports'][0]): ReportsQuery['reports'][0]['author'] =>
        report.author
      );
      return [...new Set(authors)];
    })
  );

  // All available filters return by backend
  public allFilters$ = this.filtersGQL.watch().valueChanges.pipe(
    map(({ data }: ApolloQueryResult<FiltersQuery>): FiltersQuery => data)
  );

  public objectives$ = this.allFilters$.pipe(
    map((allFilters: FiltersQuery): FiltersQuery['objectives'] => allFilters.objectives)
  );

  private nameFilterSource = new BehaviorSubject<string>('');
  public nameFilter$ = this.nameFilterSource.asObservable();

  private authorFilterSource = new BehaviorSubject<string>('');
  public authorFilter$ = this.authorFilterSource.asObservable();

  private objectivesFilterSource = new BehaviorSubject<string[]>([]);
  public objectivesFilter$ = this.objectivesFilterSource.asObservable();


  public reports$ = combineLatest([
    this.allReports$,
    this.nameFilter$,
    this.authorFilter$,
    this.objectivesFilter$
  ]).pipe(
    map(([reports, nameFilter, authorFilter, objectivesFilter]: [ReportsQuery['reports'], string, string, string[]]):
      ReportsQuery['reports'] => {
      let reportsToFilter = [...reports];
      if (nameFilter) {
        reportsToFilter = reportsToFilter.filter((report: ReportsQuery['reports'][0]): boolean =>
          report.title.toLowerCase().includes(nameFilter.toLowerCase())
        );
      }
      if (authorFilter) {
        reportsToFilter = reportsToFilter.filter((report: ReportsQuery['reports'][0]): boolean =>
          report.author.id.toLowerCase() === authorFilter.toLowerCase()
        );
      }
      if (objectivesFilter.length > 0) {
        reportsToFilter = reportsToFilter.filter((report: ReportsQuery['reports'][0]): boolean =>
          report.objectives.some((objective: ReportsQuery['reports'][0]['objectives'][0]): boolean =>
            objectivesFilter.includes(objective.id)
          )
        );
      }
      return reportsToFilter;
    })
  );

  constructor(
    public reports: ReportsGQL,
    private auth: AuthService,
    private modal: NzModalService,
    private message: NzMessageService,
    private filtersGQL: FiltersGQL,
    private deleteReportGQL: DeleteReportGQL
  ) { }

  public ngOnInit(): void {
    // this.reportService.setMode(null, false);
    this.authorization = this.auth.user$.subscribe((user: AuthResult['user']): string => this.authorId = user.id);
  }
  public ngOnDestroy(): void {
    if (this.authorization) {
      this.authorization.unsubscribe();
    }
  }

  public openReport(reportUrl: string): void {
    window.open(reportUrl, '_blank');
  }

  public deleteConfirm(reportUrl: string, title: string): void {
    this.modal.confirm({
      nzTitle: `Are you sure you want to delete <strong>${title}</strong>?`,
      nzOnOk: (): void => this.deleteReport(reportUrl, title)
    });
  }

  public shareConfirm(reportID: string): void {
    const listURL = window.location.href;
    const urlLink = listURL.replace('list', reportID);
    this.url = urlLink;
    this.modal.confirm({
      nzTitle: this.shareConfirmTitle,
      nzContent: this.shareConfirmContent,
      nzOkText: null,
      nzIconType: ''
    });
  }

  public deleteReport(id: string, title: string): void {
    this.deleteReportGQL.mutate({
      id,
    },
      {
        refetchQueries: [{ query: ReportsDocument }] // Update list of reports
      }).subscribe(
        (_: FetchResult<DeleteReportMutation, Record<string, string>>): void => {
          this.message.success(`${title} deleted!`);
        },
        (err: ErrorEvent): void => {
          this.message.error(err.error ? err.error.message || err.error.err : err.message || err);
        }
      );
  }

  public shareReport(urlLink: string): void {
    this.message.info('The URL has been copied');
    this.modal.closeAll();
    const inputText = document.createElement('textarea');
    inputText.style.position = 'fixed';
    inputText.style.left = '0';
    inputText.style.top = '0';
    inputText.style.opacity = '0';
    inputText.value = urlLink;
    document.body.appendChild(inputText);
    inputText.focus();
    inputText.select();
    document.execCommand('copy');
    document.body.removeChild(inputText);
  }

  public filterNameChange(value: string): void {
    this.nameFilterSource.next(value);
  }

  public filterAuthorChange(value: string): void {
    this.authorFilterSource.next(value);
  }

  public filterObjectivesChange(value: string[]): void {
    this.objectivesFilterSource.next(value);
  }

  public resetFilters(): void {
    this.filterNameChange('');
    this.filterAuthorChange('');
    this.filterObjectivesChange([]);
  }
}
