<div fxLayout="row" fxLayoutAlign="start stretch" class="panel" fxFlexFill>
  <div fxFlex="1 1 100%" class="body" fxLayout="column" fxLayoutAlign="start stretch">
    <nz-tabset fxFlex="1 1 100%" *ngIf="figures$ | async as figures" [nzSelectedIndex]="this.ui.selectedTab$ | async"
      (nzSelectChange)="updateTabChange($event)" [nzAnimated]="false">
      <nz-tab [nzTitle]="titleTemplate1">
        <ng-template #titleTemplate1>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2px" class="tab-title">
            <i nz-icon nzType="table"></i>
            Tables
          </div>
        </ng-template>
        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px" fxFlexFill class="panel-content"
          snInViewport (inViewportChange)="tabIsInView($event, 0)">
          <ng-container *ngFor="let table of figures.tables">
            <ng-container *ngIf="table.__typename === 'StyledTable'; else tableTemplate">
              <div fxLayout="column" class="figure-container">
                <app-styled-table fxFlex="1 1 100%" [small]="true" [styledTable]="table"></app-styled-table>
                <div fxLayout="row" fxFlex="1 1 auto" fxLayoutGap="10px" fxLayoutAlign="start stretch">
                  <div fxFlex="0 0 auto" (click)="showModal(table)" class="action-button">
                    <i nz-icon nzType="fullscreen" nzTheme="outline"></i>
                    Full Size
                  </div>
                  <div fxFlex="0 0 auto" (click)="downloadStyledXLSX(table)" class="action-button">
                    <i nz-icon nzType="download" nzTheme="outline"></i>
                    Download XLS
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-template #tableTemplate>
              <div fxLayout="column" class="figure-container">
                <app-table fxFlex="1 1 100%" [table]="table" [small]="true" [activeFilters$]="activeFilters$"></app-table>
                <div fxLayout="row" fxFlex="1 1 auto" fxLayoutGap="10px" fxLayoutAlign="start stretch">
                  <div fxFlex="0 0 auto" (click)="showModal(table)" class="action-button">
                    <i nz-icon nzType="fullscreen" nzTheme="outline"></i>
                    Full Size
                  </div>
                  <div fxFlex="0 0 auto" (click)="downloadXLSX(table)" class="action-button">
                    <i nz-icon nzType="download" nzTheme="outline"></i>
                    Download XLS
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate2">
        <ng-template #titleTemplate2>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2px" class="tab-title">
            <i nz-icon nzType="file-image"></i>
            Figures
          </div>
        </ng-template>
        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px" class="panel-content" snInViewport
          (inViewportChange)="tabIsInView($event, 1)">
          <ng-container *ngFor="let chart of figures.charts">
            <app-chart fxFlex="1 1 auto" [chart]="chart" [small]="true"
              (chartLoading)="this.ui.updateLoadingState($event)" class="figure-container chart" [activeFilters$]="activeFilters$" [showActions]="true"></app-chart>
          </ng-container>
          <ng-container *ngFor="let image of figures.images">
            <app-image fxFlex="1 1 auto" [image]="image" [small]="true" [showActions]="true"></app-image>
          </ng-container>
        </div>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate3">
        <ng-template #titleTemplate3>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2px" class="tab-title">
            <i nz-icon nzType="book"></i>
            References
          </div>
        </ng-template>
        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px" fxFlexFill class="panel-content"
          snInViewport (inViewportChange)="tabIsInView($event, 2)">
          <ng-container *ngFor="let reference of (references$ | async)">
            <app-reference fxFlex="1 1 auto" [reference]="reference" [small]="true"></app-reference>
          </ng-container>
        </div>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate4">
        <ng-template #titleTemplate4>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2px" class="tab-title">
            <i nz-icon nzType="snippets"></i>
            Supplementary
          </div>
        </ng-template>
        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px" fxFlexFill class="panel-content"
          snInViewport (inViewportChange)="tabIsInView($event, 3)">
          <ng-container *ngFor="let supplementary of (this.supplementary$ | async)">
            <app-supplementary fxFlex="1 1 auto" [supplementary]="supplementary"></app-supplementary>
          </ng-container>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
</div>

<ng-template #modalFullSizeView>
  <ng-container *ngIf="tableModal.__typename === 'StyledTable'; else tableModalTemplate">
    <app-styled-table fxFlex="1 1 auto" [small]="false" [styledTable]="tableModal" [noModal]="false"></app-styled-table>
  </ng-container>
  <ng-template #tableModalTemplate>
    <app-table fxFlex="1 1 auto" [table]="tableModal" [small]="false" [noModal]="false" [activeFilters$]="activeFilters$"></app-table>
  </ng-template>
</ng-template>