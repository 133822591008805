<div fxLayout="column" fxLayoutAlign="start stretch">
  <div fxFlex="0 0 70px" fxLayout="row" fxLayoutAlign="start stretch" class="top">
    <div fxFlex="0 0 150px" fxLayout="column" fxLayoutAlign="center center">
      <img fxFlex="0 0 45%" src="assets/logo-icon.png">
    </div>

    <div fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="0 1 80%" fxLayout="column" fxLayoutAlign="center start" *ngIf="getMasterInfo$ | async as masterInfo">
        {{masterInfo.masterInfo.title}} (ver. {{masterInfo.masterInfo.version}})
        <ng-container *ngIf="reportTitle">
          <div fxFlex="0 1 auto" class="subtitle">Report: {{reportTitle}}</div>
        </ng-container>
      </div>
    </div>

    <div fxFlex="0 0 100px" *ngIf="auth.user$ | async as user" fxLayout="row" fxLayoutAlign="space-evenly center"
      class="user" nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'">
      <div fxFlex="0 0 auto">
        {{user.username}}
      </div>
      <i nz-icon nzType="user" nzTheme="outline"></i>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="auth.logout()">Logout</li>
      </ul>
    </nz-dropdown-menu>
  </div>

  <div fxFlex="0 0 55px" fxLayout="row" fxLayoutAlign="start center" class="bottom" fxLayoutGap="20px">
    <div fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex="0 0 auto">
        <button fxFlex="0 0 auto" nz-button nzType="primary" [nzSize]="'default'" (click)="toggleReportList()">
          <ng-container *ngIf="listMode; else masterTemplate">
            <i nz-icon nzType="folder" nzTheme="outline"></i>
            Master report
          </ng-container>
          <ng-template #masterTemplate>
            <i nz-icon nzType="folder-open" nzTheme="outline"></i>
            Reports
          </ng-template>
        </button>
      </div>


      <ng-container *ngIf="!listMode">
        <!-- duplicate view -->
        <ng-container *ngIf="isDuplicate$ | async; else noDuplicate">
          <div fxFlex="0 0 auto">
            <button fxFlex="0 0 auto" nz-button nzType="primary" [nzSize]="'default'" class="save-button"
              (click)="saveReport()"><i nz-icon nzType="plus-circle" nzTheme="outline"></i>Save new
              report</button>
          </div>
          <div fxFlex="0 0 auto">
            <button fxFlex="0 0 auto" nz-button nzType="primary" [nzSize]="'default'" class="save-button"
              (click)="cancelUpdate()"><i nz-icon nzType="close-circle" nzTheme="outline"></i>Cancel</button>
          </div>
        </ng-container>
        <ng-template #noDuplicate>
          <!-- edit report view -->
          <ng-container *ngIf="id; else elseBlock">
            <div fxFlex="0 0 auto">
              <button fxFlex="0 0 auto" nz-button nzType="primary" [nzSize]="'default'" class="save-button"
                (click)="updateReport()"><i nz-icon nzType="check-circle" nzTheme="outline"></i>Save changes</button>
            </div>
            <div fxFlex="0 0 auto">
              <button fxFlex="0 0 auto" nz-button nzType="primary" [nzSize]="'default'" class="save-button"
                (click)="cancelUpdate()"><i nz-icon nzType="close-circle" nzTheme="outline"></i>Cancel</button>
            </div>
          </ng-container>
          <!-- master view -->
          <ng-template #elseBlock>
            <div fxFlex="0 0 auto">
              <button fxFlex="0 0 auto" nz-button nzType="primary" [nzSize]="'default'" (click)="saveReport()"
                class="save-button"><i nz-icon nzType="plus-circle" nzTheme="outline"></i>Save new
                report</button></div>
          </ng-template>
        </ng-template>
      </ng-container>

    </div>


    <div *ngIf="!listMode" fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="end stretch" fxLayoutGap="10px"
      style="padding-right: 200px;">

      <div class="switch" fxFlex="0 0 auto" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
          <nz-switch fxFlex="0 0 auto" nzSize="small"
            [ngModel]="ui.filtersStatus === 'out'" (ngModelChange)="ui.toggleFilters()">
          </nz-switch>
          <span fxFlex="0 0 auto">Hide filters</span>
      </div>

      <!-- <div fxFlex="0 0 auto">
        <button fxFlex="0 0 auto" nz-button nzType="primary" [nzSize]="'default'" class="filter-button"
          (click)="ui.toggleFilters()">
          <i nz-icon nzType="filter" nzTheme="outline"></i>
          <span *ngIf="ui.filtersStatus === 'out'" style="padding-right: 5px;">Hide</span>
          <span [ngClass]="{ 'lowercase': ui.filtersStatus === 'out' }">Filters</span>
        </button>
      </div> -->
    </div>

    <div fxFlex="0 0 auto" *ngIf="!listMode" class="abbreviations-button">
      <button fxFlex="0 0 auto" nz-button nzType="primary" [nzSize]="'default'" (click)="toggleAbbreviations()"><i
          nz-icon nzType="profile" nzTheme="outline"></i>
        <span *ngIf="showAbbreviations" style="padding-right: 5px;">Hide</span>
        <span [ngClass]="{ 'lowercase': showAbbreviations }">Abbreviations</span>
      </button>
    </div>
  </div>
</div>

<!-- Abbreviations modal-->
<nz-modal [(nzVisible)]="showAbbreviations" nzTitle="Abbreviations" [nzFooter]="modalFooter"
  (nzOnCancel)="toggleAbbreviations()">
  <div fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill>
    <div fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="center" class="container-abbrev">
      <div fxFlex="0 1 100%" fxLayout="column" fxLayoutAlign="start">
        <!-- Search bar -->
        <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center" class="container-search">
          <div fxFlex="1 1 100%">
            <nz-input-group [nzPrefix]="suffixIconSearch" [nzSuffix]="inputClearTpl">
              <input [ngModel]="acronymFilter$ | async" type="text" nz-input placeholder="Search by acronym"
                (ngModelChange)="filterAcronymChange($event)" />
            </nz-input-group>
            <ng-template #suffixIconSearch>
              <i nz-icon nzType="search"></i>
            </ng-template>
            <ng-template #inputClearTpl>
              <i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle"
                *ngIf="(acronymFilter$ | async)!==''" (click)="filterAcronymChange('')"></i>
            </ng-template>
          </div>
        </div>
        <!-- abbreviations -->
        <div fxFlex="0 0 auto" fxLayout="row" class="abbrev-title" fxLayoutAlign="start center" fxLayoutGap="15px">
          <div fxFlex="1 1 20%">Acronym</div>
          <div fxFlex="1 1 80%" class="author">Description</div>
        </div>
        <div fxFlex="0 0 auto" *ngFor="let abbreviation of abbreviations$ | async" fxLayout="row" class="abbrev-item"
          fxLayoutAlign="start center" fxLayoutGap="15px">
          <div fxFlex="1 1 20%">
            {{ abbreviation.acronym }}
          </div>
          <div fxFlex="1 1 80%" class="author">
            {{ abbreviation.description }}
          </div>
        </div>

      </div>
    </div>
  </div>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="toggleAbbreviations()">Close</button>
  </ng-template>
</nz-modal>
