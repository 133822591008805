<ng-container *ngIf="reference; let reference">

    <div fxFlex="1 1 100%" fxLayout="row" class="reference-container" [ngClass]="{'small': small}" [id]="referenceID">
        <span fxFlex="1 1 10%" class="reference-number" [ngClass]="{'small': small}">{{ reference.referenceNumber }}</span>
        <div fxFlex="1 1 90%" fxLayout="column">
            <markdown class="reference-description">{{ reference.description }}</markdown>
            <div fxLayout="row" fxLayoutGap="20px">
                <a *ngIf="reference.link; let link" href="{{ link }}" target="_blank" class="underline-link">PubMed</a>
                <a *ngIf="reference.fullText" [href]="reference.fullText.url" target="_blank" class="underline-link">Full text</a>
            </div>
        </div>
    </div>
</ng-container>
