import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { mergeMap, take, filter, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  // Intercept all requests except to login endpoint, or password reset
  private noAuthRequired = [
    `${environment.backend}/auth/local`,
    `${environment.backend}/auth/forgot-password`,
    `${environment.backend}/auth/reset-password`
  ];

  constructor(
    private auth: AuthService,
  ) { }

  public intercept(
    req: HttpRequest<{}>,
    next: HttpHandler
  ): Observable<HttpEvent<{}>> {

    // Custom viewer mode
    // Server no longer contains an variable to distinguish viewer mode and editor mode
    const editorMode = ['editor', 'list', 'clone'].some(
      (el: string): boolean => {
        const urlLink = window.location.href;
        return urlLink.includes(el);
      }
    );

    if (this.noAuthRequired.includes(req.url) || !this.auth.isAuthenticated() || !editorMode) {
      return next.handle(req);
    }

    const requestWithAuth$ = this.auth.jwt$.pipe(
      filter((token: string): boolean => !!token),
      take(1),
      mergeMap((token: string): Observable<HttpEvent<{}>> => {
        const tokenReq = req.clone({
          setHeaders: { Authorization: `Bearer ${token}` }
        });
        return next.handle(tokenReq).pipe(
          tap((event: HttpEvent<{}>): void => {}, (err: Error): void => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.auth.logout();
              }
            }
          })
        );
      })
    );

    return requestWithAuth$;
  }

}
