import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { MatomoInjector } from 'ngx-matomo';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private matomoInjector: MatomoInjector,
  ) {
    if (environment.environment === 'production') {
      this.matomoInjector.init('https://analytics.eviviz.com/', 1);
    }
  }

  public ngOnInit(): void {
    this.auth.loadLocalStorage();
  }
}
