import { MatomoTracker } from 'ngx-matomo';
import { MasterInfoGQL, MasterInfoQuery } from './../graphql/graphql';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { UiService } from './../ui.service';
import { AuthService } from './../auth.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbbreviationsGQL, AbbreviationsQuery } from '../graphql/graphql';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from 'apollo-client';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {

  public showAbbreviations = false;

  @Input() public listMode = false;
  @Input() public id: string;
  @Input() public reportTitle: string;
  @Output() private update = new EventEmitter<string>();
  @Output() private save = new EventEmitter<string>();
  @Output() private cancel = new EventEmitter<null>();

  public getMasterInfo$ = this.masterInfoGQL.watch().valueChanges.pipe(
    map(({ data }: ApolloQueryResult<MasterInfoQuery>): MasterInfoQuery => data)
  );

  public reportId$ = this.route.params.pipe(
    map((params: Params): string => params.reportId)
  );
  public isDuplicate$ = this.route.data.pipe(
    map((data: Data): boolean =>
      data.clone ? true : false)
  );
  // abbreviations
  private acronymFilterSource = new BehaviorSubject<string>('');
  public acronymFilter$ = this.acronymFilterSource.asObservable();

  private allAbbreviations$ =
    this.abbreviationsGQL.watch().valueChanges.pipe(
      map(({ data }: ApolloQueryResult<AbbreviationsQuery>): AbbreviationsQuery['abbreviations'] => data.abbreviations)
    );

  public abbreviations$ = combineLatest([
    this.allAbbreviations$,
    this.acronymFilter$
  ]).pipe(
    map(([abbreviations, acronym]: [AbbreviationsQuery['abbreviations'], string]): AbbreviationsQuery['abbreviations'] => {
      let dataToFilter = [...abbreviations];
      if (acronym) {
        dataToFilter = dataToFilter.filter((abbreviation: AbbreviationsQuery['abbreviations'][0]): boolean =>
          abbreviation.acronym.toLowerCase().includes(acronym.toLowerCase()));
      }
      return dataToFilter;
    })
  );

  constructor(
    public auth: AuthService,
    public ui: UiService,
    private router: Router,
    private route: ActivatedRoute,
    private abbreviationsGQL: AbbreviationsGQL,
    private masterInfoGQL: MasterInfoGQL,
    private matomoTracker: MatomoTracker,
  ) { }

  public toggleReportList(): void {
    const goTo = this.listMode ? 'editor' : 'list';
    this.router.navigate([goTo]);
    this.matomoTracker.trackEvent('switch mode', goTo);
  }
  public toggleAbbreviations(): void {
    const toggle = this.showAbbreviations ? false : true;
    this.showAbbreviations = toggle;
    this.matomoTracker.trackEvent('toggle abbreviations', `${this.showAbbreviations}`);
  }

  public filterAcronymChange(value: string): void {
    this.acronymFilterSource.next(value);
  }

  public updateReport(): void {
    this.update.emit(this.id);
    this.matomoTracker.trackEvent('report', 'update');
  }

  public cancelUpdate(): void {
    this.cancel.emit();
    this.matomoTracker.trackEvent('report', 'cancel update');
  }

  public saveReport(): void {
    this.save.emit(this.id);
    this.matomoTracker.trackEvent('report', 'save');
  }

}
