import { TocQuery, SectionsQuery, TocGQL } from './../graphql/graphql';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { getSectionDepth, getSectionNumber } from '../helpers';
import { combineLatest, Observable } from 'rxjs';
import { UiService } from '../ui.service';
import { ApolloQueryResult } from 'apollo-client';
import { map, filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { NumberedSection } from '../types';

@Component({
  selector: 'app-contents',
  templateUrl: './contents.component.html',
  styleUrls: ['./contents.component.scss']
})
export class ContentsComponent implements OnInit {
  public getSectionDepth = getSectionDepth;
  public getSectionNumber = getSectionNumber;

  public menuMaxDepth = 2;

  @Input() public filteredReport$: Observable<NumberedSection[]>;
  @Output() private sectionChange = new EventEmitter<SectionsQuery['sections'][0]>();

  // Filter tree-like TOC by flat array of active sections
  public toc$: Observable<TocQuery['sections']>;

  public selectedSectionNumber$ = this.route.fragment;
  public selectedRootSectionNumber$ = this.selectedSectionNumber$.pipe(
    map((sectionInView: string): number => {
      try {
        const [order1] = sectionInView.split('.');
        return parseInt(order1, 10);
      } catch (error) { }
      return 0;
    }),
    filter((rootSectionNumber: number): boolean => !!rootSectionNumber)
  );

  public ngOnInit(): void {
    this.toc$ = combineLatest([
      this.filteredReport$,
      this.toc.watch().valueChanges.pipe(
        map((result: ApolloQueryResult<TocQuery>): TocQuery['sections'] => result.data.sections)
      )
    ]).pipe(
      map(([activeSections, tocSections]: [SectionsQuery['sections'], TocQuery['sections']]): TocQuery['sections'] => {
        const filterEntries = (entries: TocQuery['sections']): TocQuery['sections'] => {
          const filteredEntries: TocQuery['sections'] = [];
          entries.forEach((entry: TocQuery['sections'][0]): void => {
            if (activeSections.some((activeSection: SectionsQuery['sections'][0]): boolean => activeSection.id === entry.id)) {
              const processedEntry = { ...entry };
              if (entry.sections) {
                processedEntry.sections = filterEntries(entry.sections);
              }
              filteredEntries.push(processedEntry);
            }
          });
          return filteredEntries;
        };
        return filterEntries(tocSections);
      })
    );
  }
  constructor(
    private route: ActivatedRoute,
    public ui: UiService,
    private toc: TocGQL
  ) { }

  public select(section: SectionsQuery['sections'][0]): void {
    this.sectionChange.emit(section);
  }

}
