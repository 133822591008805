import { Filters, TableRow, FilterRow, NumberedSection, ReplacementMap } from './types';
import { SectionsQuery, Reference } from './graphql/graphql';

export const getSectionDepth = (section: SectionsQuery['sections'][0]): number => {
    if (section.order4) {
        return 4;
    }
    if (section.order3) {
        return 3;
    }
    if (section.order2) {
        return 2;
    }
    return 1;
};

/**
 * Returns section number as it appears in Master Report, e.g. 6.1.1
 * @param section Section to assemble number for
 */
export const getSectionNumber = (section: SectionsQuery['sections'][0]): string => {
    if (!section.order1) {
        return '';
    }
    let sectionNumber = `${section.order1}`;
    if (section.order2) {
        sectionNumber += `.${section.order2}`;
    }
    if (section.order3) {
        sectionNumber += `.${section.order3}`;
    }
    if (section.order4) {
        sectionNumber += `.${section.order4}`;
    }
    return sectionNumber;
};


const filterMatch = (filterValue: string, valueToCheck: string | string[] | number): boolean => {
    if (Array.isArray(valueToCheck)) {
        return valueToCheck.includes(filterValue);
    }
    return valueToCheck === filterValue;
};

/**
 * Filters table data using global filters
 * @param unfilteredData Array of table data
 * @param filters Global filters
 * @param filterMap Map between Filter name and Field name in table
 */
export const filterData = (
    unfilteredData: TableRow[],
    filters: Filters,
    filterMap: Map<string, string>
): TableRow[] =>
    unfilteredData.filter(
        (entry: TableRow): boolean => {
            for (const [filterName, fieldName] of filterMap) {
                if (filters[filterName] && filters[filterName].length > 0) {
                  if (
                      fieldName === 'Country'
                      && filterName === 'countries'
                      && ( entry[fieldName].toString().includes('Global') || entry[fieldName].toString().includes('International'))
                      && filters[filterName].some((filterValue: FilterRow): boolean => filterValue.value)
                  ) {
                      return true;
                  } else if (
                      !filters[filterName].some(
                          (filterValue: FilterRow): boolean =>
                              filterValue.value
                              && (filterMatch(filterValue.name, entry[fieldName]) || filterMatch(filterValue.partOf, entry[fieldName]))
                      )
                  ) {
                      return false;
                  }
                }
            }
            return true;
        });

/**
 * Assigns correct numbers to sections, tables, figures and references
 * @param sections Filtered sections
 */
export const enumerate = (sections: SectionsQuery['sections']): NumberedSection[] => {
    const numberedSections: NumberedSection[] = [];
    const sectionNumberSeparator = '.';
    const figureNumberSeparator = '-';
    const sectionCounter = [1, 1, 1, 1];
    const replacementMap: ReplacementMap = {
        Table: {},
        Figure: {},
        Reference: {},
        Appendix: {}
    };
    let tablesInSectionCounter = 0;
    let chartsInSectionCounter = 0;
    let referencesGlobalCounter = 0;
    let appendixTablesCounter = 0;

    sections.forEach((currentSection: SectionsQuery['sections'][0], i: number): void => {
        const previousSection = i > 0 ? sections[i - 1] : currentSection;
        // Calculate current section order number
        if (currentSection.order1 !== previousSection.order1) {
            sectionCounter[0]++;
            sectionCounter[1] = 1;
            sectionCounter[2] = 1;
            sectionCounter[3] = 1;
            tablesInSectionCounter = 0;
            chartsInSectionCounter = 0;
        } else if (previousSection.order2 && currentSection.order2 !== previousSection.order2) {
            sectionCounter[1]++;
            sectionCounter[2] = 1;
            sectionCounter[3] = 1;
        } else if (previousSection.order3 && currentSection.order3 !== previousSection.order3) {
            sectionCounter[2]++;
            sectionCounter[3] = 1;
        } else if (previousSection.order4 && currentSection.order4 !== previousSection.order4) {
            sectionCounter[3]++;
        }

        if (currentSection.content) {
            // Assign numbers to tables and charts
            currentSection.content.forEach((contentItem: NumberedSection['content'][0]): void => {
                switch (contentItem.__typename) {
                    case 'ComponentReportContentStyledTable':
                        tablesInSectionCounter++;
                        let styledTableNumber = '';
                        if (currentSection.order1 === 100 && contentItem.styled_table.number) {
                            appendixTablesCounter++;
                            styledTableNumber = `A ${appendixTablesCounter}`;
                        } else {
                            styledTableNumber = `${sectionCounter[0]}${figureNumberSeparator}${tablesInSectionCounter}`;
                        }
                        // tslint:disable-next-line: no-any
                        (contentItem.styled_table as any).tableNumber = styledTableNumber;
                        replacementMap.Table[contentItem.styled_table.number] = styledTableNumber;
                        break;
                    case 'ComponentReportContentTable':
                        tablesInSectionCounter++;
                        let tableNumber = '';
                        if (currentSection.order1 === 100 && contentItem.table.number) {
                            appendixTablesCounter++;
                            tableNumber = `A ${appendixTablesCounter}`;
                        } else {
                            tableNumber = `${sectionCounter[0]}${figureNumberSeparator}${tablesInSectionCounter}`;
                        }
                        // tslint:disable-next-line: no-any
                        (contentItem.table as any).tableNumber = tableNumber;
                        replacementMap.Table[contentItem.table.number] = tableNumber;
                        break;
                    case 'ComponentReportContentChart':
                        chartsInSectionCounter++;
                        const chartNumber = `${sectionCounter[0]}${figureNumberSeparator}${chartsInSectionCounter}`;
                        // tslint:disable-next-line: no-any
                        (contentItem.chart as any).chartNumber = chartNumber;
                        replacementMap.Figure[contentItem.chart.number] = chartNumber;
                        break;
                }
            });
            if (currentSection.references && currentSection.references.length > 0) {
                currentSection.references.forEach((reference: Reference): void => {
                    if (reference.number && !replacementMap.Reference.hasOwnProperty(reference.number.toString())) {
                        referencesGlobalCounter++;
                        // tslint:disable-next-line: no-any
                        (reference as any).referenceNumber = referencesGlobalCounter;
                        replacementMap.Reference[reference.number.toString()] = referencesGlobalCounter;
                    } else if (reference.number) {
                        // tslint:disable-next-line: no-any
                        (reference as any).referenceNumber = replacementMap.Reference[reference.number.toString()];
                    }
                });
            }
        }

        // Assign section number
        let sectionNumber = `${sectionCounter[0]}`;
        // currentSection.order1 === 100 means Appendix section;
        if (currentSection.order1 === 100 && currentSection.order2) {
            sectionNumber = `A${sectionNumberSeparator}${sectionCounter[1]}`;
            replacementMap.Appendix[`A${sectionNumberSeparator}${currentSection.order2}`] = sectionNumber;
        } else if (currentSection.order2) {
            sectionNumber += `${sectionNumberSeparator}${sectionCounter[1]}`;
        }
        if (currentSection.order3) {
            sectionNumber += `${sectionNumberSeparator}${sectionCounter[2]}`;
            if (currentSection.order1 === 100) {
                // tslint:disable-next-line: max-line-length
                replacementMap.Appendix[`A${sectionNumberSeparator}${currentSection.order2}${sectionNumberSeparator}${currentSection.order3}`] = sectionNumber;
            }
        }
        if (currentSection.order4) {
            sectionNumber += `${sectionNumberSeparator}${sectionCounter[3]}`;
            if (currentSection.order1 === 100) {
                // tslint:disable-next-line: max-line-length
                replacementMap.Appendix[`A${sectionNumberSeparator}${currentSection.order2}${sectionNumberSeparator}${currentSection.order3}${sectionNumberSeparator}${currentSection.order4}`] = sectionNumber;
            }
        }

        numberedSections.push({
            ...currentSection,
            sectionNumber
        });
    });

    numberedSections.forEach((currentSection: SectionsQuery['sections'][0]): void => {
        if (currentSection.content) {
            currentSection.content.forEach((contentItem: NumberedSection['content'][0]): void => {
                if (contentItem.__typename === 'ComponentReportContentParagraph' && contentItem.paragraph && contentItem.paragraph.body) {
                    contentItem.paragraph.body = contentItem.paragraph.body
                    // Replace table and figure numbers in paragraphs
                    .replace(
                        /<a(?:\sclass="(?:tab|fig)-\d+-\d+")*>(?<urlType>Table|Figure)\s(?<masterNumber>\d+-\d+)<\/a>/g,
                        (_: string, urlType: 'Table' | 'Figure', masterNumber: string): string =>
                            `<a class="${urlType === 'Table' ? 'tab' : 'fig'}-${replacementMap[urlType][masterNumber] || masterNumber}">${urlType} ${replacementMap[urlType][masterNumber] || masterNumber}</a>`
                    ).replace(
                        // tables from appendix
                        /<a(?:\sclass="tab-A\s\d+")*>Table\s(?<masterNumber>A\s\d+)<\/a>/g,
                        (_: string, masterNumber: string): string =>
                            `<a class="tab-${replacementMap.Table[masterNumber] || masterNumber}">Table ${replacementMap.Table[masterNumber] || masterNumber}</a>`
                    ).replace(
                        /Appendix\s(?<masterNumber>A.\d+(.\d)*(.\d)*)/g,
                        (_: string, masterNumber: string): string =>
                            `Appendix ${replacementMap.Appendix[masterNumber] || masterNumber}`
                    ).replace(
                        // Replace reference numbers in paragraphs
                        /<sup(?:\sclass="ref-\d+")*>(?<masterNumber>\d+)/g,
                        (_: string, masterNumber: string): string =>
                            `<sup class="ref-${replacementMap.Reference[masterNumber] || masterNumber}">${replacementMap.Reference[masterNumber] || masterNumber}`
                    );
                } else if (contentItem.__typename === 'ComponentReportContentTable' && contentItem.table) {
                    if (contentItem.table.footnotes) {
                        contentItem.table.footnotes = contentItem.table.footnotes.replace(
                            // Replace reference numbers in table footnotes
                            /<sup(?:\sclass="ref-\d+")*>(?<masterNumber>\d+)/g,
                            (_: string, masterNumber: string): string =>
                                `<sup class="ref-${replacementMap.Reference[masterNumber] || masterNumber}">${replacementMap.Reference[masterNumber] || masterNumber}`
                        );
                    }
                    if (contentItem.table.data) {
                        contentItem.table.data.forEach((line: object): void =>
                            Object.keys(line).forEach((key: string): string => {
                                if (line[key]) {
                                    return line[key] = (line[key].toString()).replace(
                                    // Replace reference numbers in table body
                                    /<sup(?:\sclass="ref-\d+")*>(?<masterNumber>\d+)/g,
                                    (_: string, masterNumber: string): string =>
                                        `<sup class="ref-${replacementMap.Reference[masterNumber] || masterNumber}">${replacementMap.Reference[masterNumber] || masterNumber}`
                                    ).replace(
                                        /<a(?:\sclass="ref-\d+")*>(?<masterNumber>\d+)/g,
                                        (_: string, masterNumber: string): string =>
                                            `<a class="ref-${replacementMap.Reference[masterNumber] || masterNumber}">${replacementMap.Reference[masterNumber] || masterNumber}`
                                    );
                                }
                                return line[key];
                            })
                        );
                    }
                }
            });
        }
    });

    return numberedSections;
};
