import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { environment } from '../../environments/environment';

import introspectionResult from './graphql.introspection';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloLink, concat } from 'apollo-link';
import { GraphQLError } from 'graphql';
import { onError } from 'apollo-link-error';
import { DefaultOptions } from 'apollo-client';
import { AuthService } from '../auth.service';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult
});
const cache = new InMemoryCache({
  fragmentMatcher
});


const uri = `${environment.backend}/graphql`;

export function createApollo(httpLink: HttpLink, auth: AuthService): { link: ApolloLink, cache: InMemoryCache, defaultOptions?: DefaultOptions } {
  // Manually error-out request if server return GraphQL Forbidden. Otherwise Apollo caches the error
  const logout = onError(({ graphQLErrors }) => {
    if (
      graphQLErrors &&
      graphQLErrors.some((error: GraphQLError): boolean => error.message === 'Forbidden')
    ) {
      auth.logout();
      throw new Error(`GraphQL Error: Forbidden`);
    }
  });
  return {
    cache, // InMemoryCache with custom fragment matcher
    link: concat(logout, httpLink.create({ uri })),
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService],
    },
  ],
})
export class GraphQLModule { }
