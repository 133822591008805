<div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px" class="filter-body">
  <ng-container *ngIf="allFilters$ | async as allFilters">

    <ng-container *ngIf="selectedFilters$ | async as selected">

      <div fxFlex="1 1 100%" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start stretch">
        <ng-container *ngFor="let filterGroup of ['objectives', 'countries', 'settings']">
          <ng-container *ngIf="allFilters[filterGroup] as filter">
            <ng-container *ngIf="filterIsVisible(filterGroup, selected)">
              <span class="filter-group-heading">{{ filterGroup }}</span>
              <nz-select fxFlex="1 1 auto" [ngModel]="selected[filterGroup]" ngDefaultControl
                (ngModelChange)="filterChange(selected, filterGroup, $event, filter)" nzAllowClear
                nzPlaceHolder="{{filterGroup | titlecase}}" nzMode="multiple" class="filter-cell">
                <nz-option *ngFor="let value of filter" [nzValue]="value.name" [nzLabel]="value.name">
                </nz-option>
              </nz-select>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start center">
        <button fxFlex="0 0 auto" nz-button nzType="primary" [nzSize]="'default'" style="margin-top: 26px;"
          (click)="applyFilters()" [nzLoading]="loadingData" [disabled]="loadingData || (filtersNotChanged$ | async)">
          <i nz-icon nzType="check" nzTheme="outline"></i>
          Apply
        </button>
      </div>

    </ng-container>

  </ng-container>
</div>