<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px" fxFlexFill class="container">
    <ng-container *ngIf="!small; else smallTemplate">
        <div [fxFlex]="chartHeight" *ngIf="options" echarts [options]="options" class="chart"
            [ngClass]="{'small': small}" (chartInit)="chartInit($event)" (chartFinished)="chartFinished()">
        </div>
    </ng-container>
    <ng-template #smallTemplate>
        <div [fxFlex]="chartHeight" *ngIf="options" echarts [options]="options" class="chart"
            [ngClass]="{'small': small}" (chartInit)="chartInit($event)" (chartFinished)="chartFinished()"></div>
        <img  [id]="figureID" fxFlex="1 1 100%" [src]="src" (click)="showModal(chart)" style="cursor: pointer;">
    </ng-template>

    <markdown class="footnotes" fxFlex="0 0 auto" *ngIf="chart.table && chart.table.footnotes && !small"
        [data]="chart.table.footnotes" (click)="ui.onMarkdownClick($event);"></markdown>
    <div *ngIf="noModal" fxLayout="row" fxLayoutAlign="start center" class="title">
        Figure {{chart.chartNumber}}: {{chart.title}}
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" *ngIf="noModal && showActions">
        <div fxFlex="0 0 auto" (click)="showModal(chart)" class="action-button">
            <i nz-icon nzType="fullscreen" nzTheme="outline"></i>
            Full Size
        </div>
        <div fxFlex="0 0 auto" (click)="downloadPNG()" class="action-button">
            <i nz-icon nzType="download" nzTheme="outline"></i>
            Download PNG
        </div>
        <div fxFlex="0 0 auto" (click)="downloadFigureXLSX()" class="action-button">
            <i nz-icon nzType="download" nzTheme="outline"></i>
            Download XLS
        </div>
    </div>
</div>

<ng-template #modalFullSizeView>
    <app-chart fxFlex="1 1 auto" [chart]="chart" [small]="false" [noModal]="false" [activeFilters$]="activeFilters$"></app-chart>
</ng-template>