<div fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill>
    <app-topbar fxFlex="0 0 auto" [listMode]="true"></app-topbar>

    <!-- Filter -->
    <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="start center" class="container-filter" fxLayoutGap="15px">
        <div fxFlex="1 1 150px">
            <nz-input-group [nzPrefix]="suffixIconSearch" [nzSuffix]="inputClearTpl">
                <input [ngModel]="nameFilter$ | async" (ngModelChange)="filterNameChange($event)" type="text"
                    nz-input placeholder="Search by name" />
            </nz-input-group>
            <ng-template #suffixIconSearch>
                <i nz-icon nzType="search"></i>
            </ng-template>
            <ng-template #inputClearTpl>
                <i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" *ngIf="(nameFilter$ | async)!==''" (click)="filterNameChange('')"></i>
            </ng-template>
        </div>
        <div fxFlex="1 0 auto">
            Filter by:
        </div>
        <nz-select fxFlex="1 1 150px" [ngModel]="authorFilter$ | async"
            (ngModelChange)="filterAuthorChange($event)" nzAllowClear nzPlaceHolder="Author">
            <nz-option *ngFor="let author of (authors$ | async)" [nzValue]="author.id"
                [nzLabel]="author.id === this.authorId ? 'me' : author.username">
            </nz-option>
        </nz-select>
        <nz-select fxFlex="1 1 100%" [ngModel]="objectivesFilter$ | async"
            (ngModelChange)="filterObjectivesChange($event)" nzAllowClear nzPlaceHolder="Objectives"
            nzMode="multiple">
            <nz-option *ngFor="let objective of (objectives$ | async)" [nzValue]="objective.id"
                [nzLabel]="objective.name">
            </nz-option>
        </nz-select>
        <div fxFlex="0 0 auto">
            <button nz-button nzType="default" nzShape="round" [nzSize]="'default'" (click)="resetFilters()"
                [disabled]="(nameFilter$ | async) === '' && (authorFilter$ | async) === '' && (objectivesFilter$ | async).length === 0">CLEAR</button>
        </div>
    </div>


    <!-- Report entries -->
    <div fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="center" class="container-reports">
        <div fxFlex="0 1 800px" fxLayout="column" fxLayoutAlign="start">
            <div fxFlex="0 0 auto" *ngFor="let report of reports$ | async" class="report-item" fxLayout="row"
                fxLayoutAlign="start center" fxLayoutGap="15px">
                <!-- <div fxFlex="0 0 auto">
                    <label nz-checkbox [ngModel]="false"></label>
                </div> -->
                <div fxFlex="1 1 100%" class="title">
                    {{ report.title }}
                </div>
                <div fxFlex="0 0 100px" class="author">
                    {{ report.author.username}}
                </div>
                <div fxFlex="0 0 auto" class="date">
                    {{report.createdAt | date:'dd MMM yyyy'}}
                </div>
                <div fxFlex="0 0 100px">
                    <button nz-button nz-dropdown [nzDropdownMenu]="actionMenu" [nzTrigger]="'click'">
                        Actions
                        <i nz-icon nzType="down"></i>
                    </button>
                </div>
                <nz-dropdown-menu #actionMenu="nzDropdownMenu">
                    <ul nz-menu *ngIf="report.id as reportId">
                        <li nz-menu-item (click)="openReport(reportId)">Open in new window</li>
                        <li nz-menu-item *ngIf="this.authorId === report.author.id"
                            [routerLink]="['/editor', reportId]">Edit (as author)</li>
                        <li nz-menu-item (click)="shareConfirm(reportId)">Share</li>
                        <li nz-menu-item [routerLink]="['/clone', reportId]">Duplicate</li>
                        <li nz-menu-item (click)="deleteConfirm(reportId, report.title)">Remove</li>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </div>
</div>

<ng-template #shareConfirmTitle>
  <div><i nz-icon nzType="link" nzTheme="outline"></i> Share Report</div>
</ng-template>

<ng-template #shareConfirmContent>
  <div fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch">
    <div>Please use the following URL to share this report</div>
    <div fxLayoutout="row" fxLayoutAlign="center center" fxLayoutGap="0px">
      <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
        <input nz-input [(ngModel)]="url" readonly/>
      </nz-input-group>
      <ng-template #suffixIconButton>
        <button #button nz-button (click)="shareReport(url)" nzSearch><i nz-icon nzType="copy" nzTheme="outline"></i></button>
      </ng-template>
    </div>
  </div>
</ng-template>
