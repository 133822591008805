import { environment } from './../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ReplaySubject, concat, of, Observable, BehaviorSubject } from 'rxjs';
import { take, map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatomoTracker } from 'ngx-matomo';

export interface AuthResult {
  jwt: string;
  user: {
    blocked: boolean;
    confirmed: boolean;
    createdAt: string;
    email: string;
    id: string;
    provider: string;
    role: {
      name: string;
      description: string;
    };
    updatedAt: string;
    username: string;
  };
}

interface AuthError {
  error: {
    statusCode: number;
    error: string;
    message: { messages: { id: string; message: string; }[] }[];
    data: [{
      messages: [{ id: string; message: string; }]
    }];
  };
}

interface RequestPasswordRestResult {
  ok: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private jwtSource = new ReplaySubject<string>(1);
  public jwt$: Observable<string> = this.jwtSource.asObservable();

  private userSource = new ReplaySubject<AuthResult['user']>(1);
  public user$: Observable<AuthResult['user']> = this.userSource.asObservable();

  private loadingSource = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loadingSource.asObservable();

  public authenticated$: Observable<boolean> = concat(
    of(false),
    this.jwt$.pipe(
      map((jwt: string): boolean => !!jwt)
    ),
  ).pipe(
    shareReplay(1),
  );

  constructor(
    private http: HttpClient,
    private notification: NzNotificationService,
    private router: Router,
    private matomoTracker: MatomoTracker,
  ) { }

  public loadLocalStorage(): void {
    const jwToken = localStorage.getItem('jwToken');
    if (jwToken) {
      this.jwtSource.next(jwToken);
      // this.router.navigate(['/editor']);
    }
    const user = localStorage.getItem('user');
    if (user) {
      try {
        this.userSource.next(JSON.parse(user));
      } catch (error) {}
    }
  }

  public logout(): void {
    this.jwtSource.next('');
    localStorage.removeItem('jwToken');
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
    this.matomoTracker.trackEvent('auth', 'logout');
  }

  // TODO: Token renewal
  // TODO: return login observable for login component spinner
  public login(identifier: string, password: string, remember: boolean): void {
    this.http.post<AuthResult>(
      `${environment.backend}/auth/local`,
      {
        identifier,
        password,
      }
    ).pipe(
      take(1)
    ).subscribe(
      (res: AuthResult): void => {
        if (remember) {
          localStorage.setItem('jwToken', res.jwt);
          localStorage.setItem('user', JSON.stringify(res.user));
        }
        this.jwtSource.next(res.jwt);
        this.userSource.next(res.user);
        if (res.user) {
          this.matomoTracker.setUserId(res.user.email);
        }
        this.matomoTracker.trackEvent('auth', 'login success');
        this.router.navigate(['']);
      },
      (authError: AuthError): void => {
        this.notification.create(
          'error',
          'Authentication Error',
          authError.error.message[0].messages[0].message
        );
        this.matomoTracker.trackEvent('auth', 'login error', authError.error.message[0].messages[0].message);
      }
    );
  }

  public requestPasswordResetEmail(email: string): void {
    this.matomoTracker.trackEvent('auth', 'reset password');
    this.loadingSource.next(true);
    this.http.post<RequestPasswordRestResult>(
      `${environment.backend}/auth/forgot-password`,
      { email }
    ).pipe(
      take(1)
    ).subscribe(
      (res: RequestPasswordRestResult): void => {
        this.router.navigate(['/emailSent']);
        this.loadingSource.next(false);
      },
      (authError: AuthError): void => {
        this.notification.create(
          'error',
          'Password Reset Request Error',
          authError.error.message[0].messages[0].message
        );
        this.loadingSource.next(false);
      }
    );

  }

  public setNewPassword(password: string, passwordConfirmation: string, code: string): void {
    this.http.post<AuthResult>(
      `${environment.backend}/auth/reset-password`,
      {
        password,
        passwordConfirmation,
        code
      }
    ).pipe(
      take(1)
    ).subscribe(
      (res: AuthResult): void => {
        this.router.navigate(['']);
        this.notification.create(
          'success',
          'Password updated!',
          'Your user\'s password has been changed.'
        );
      },
      (authError: AuthError): void => {
        this.notification.create(
          'error',
          'Password Reset Error',
          authError.error.message[0].messages[0].message
        );
        this.router.navigate(['']);
      }
    );
  }

  public isAuthenticated(): boolean {
    const jwToken = localStorage.getItem('jwToken');
    return !!jwToken;
  }

}
