import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NumberedReference } from '../types';

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent implements OnInit {
  @Input() public reference: NumberedReference;
  @Input() public small = false;

  public referenceID = '';
  public environment = environment;

  constructor() { }

  public ngOnInit(): void {
    this.referenceID = this.small ? `ref-${this.reference.referenceNumber}` : '';
  }

}
