import { TableRow, Filters, NumberedTable } from './../types';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { filterData } from '../helpers';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnInit {
  @Input() public table: NumberedTable;
  @Input() public small = false;
  @Input() public noModal = true;
  @Input() public activeFilters$: Observable<Filters>;

  public tableID = '';
  public filteredData$: Observable<TableRow[]>;

  constructor(
    public ui: UiService
  ) { }

  public ngOnInit(): void {
    const filterMap = new Map<string, string>(Object.entries(this.table.filterMap || {}));

    this.filteredData$ = this.activeFilters$.pipe(
      map((filters: Filters): TableRow[] => filterData(this.table.data, filters, filterMap))
    );

    this.tableID = this.small ? `tab-${this.table.tableNumber}` : '';
  }

  public rowIsVisible = (row: TableRow, group: string, subgroup?: string): boolean =>
    (!group || group === row.group) && (!subgroup || subgroup === row.subgroup)

  public groupNotEmpty = (data: TableRow[], group: string, subgroup?: string): boolean =>
    data.filter((row: TableRow): boolean => this.rowIsVisible(row, group, subgroup)).length > 0


}
