<div fxLayout="column" fxLayoutAlign="start stretch" style="overflow: auto;" fxFlexFill>
    <app-report [report$]="report$" [references$]="references$" [activeFilters$]="filters$" fxFlex="1 1 100%"
        fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="start start"></app-report>
</div>

<div class="download-panel" fxLayout="column" fxLayoutGap=20px fxLayoutAlign="start start"
    (mouseenter)="showDownloadMenu=true" (mouseleave)="showDownloadMenu=false">
    <div fxFlex id="download-control">
        <button nz-button nzType="primary" nzShape="circle" nzSize=large [nzLoading]="download.downloading$ | async">
            <i nz-icon nzType="download"></i>
        </button>
    </div>
    <div *ngIf="showDownloadMenu" [@downloadMenu] fxFlex id="download-options" fxLayout="column" fxLayoutGap=20px
        fxLayoutAlign="start start">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap=5px>
            <button class="icon pdf" nz-button nzType="primary" nzShape="circle" nzSize=large (click)="downloadPDF()"
                [nzLoading]="download.downloading$ | async">
                <i nz-icon nzType="file-pdf" nzTheme="fill"></i>
            </button>
            <div class="label">Download PDF</div>
        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap=5px>
            <button class="icon word" nz-button nzType="primary" nzShape="circle" nzSize=large (click)="downloadWord()"
                [nzLoading]="download.downloading$ | async">
                <i nz-icon nzType="file-word" nzTheme="fill"></i>
            </button>
            <div class="label">Download Word</div>
        </div>
    </div>
</div>