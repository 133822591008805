import { Component, OnInit, Input, OnDestroy, ViewChild, TemplateRef, Output } from '@angular/core';
import { EChartOption, ECharts } from 'echarts';
import { Chart } from './../graphql/graphql';
import { Subscription, Observable } from 'rxjs';
import { Filters, NumberedChart } from '../types';
import { NzModalService } from 'ng-zorro-antd';
import { FileSaverService } from '../file-saver.service';
import { DownloadService } from '../download.service';
import { getChart } from './helpers';
import { EventEmitter } from '@angular/core';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy {
  @Input() public chart: NumberedChart;
  @Input() public small = false;
  @Input() public noModal = true;
  @Input() public activeFilters$: Observable<Filters>;
  @Input() public showActions: boolean;
  @Output() public chartLoading: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('modalFullSizeView', { static: true }) private modalFullSizeView: TemplateRef<{}>;

  private subscription: Subscription;
  public chartModal: Partial<Chart>;
  public src: string;
  public options: EChartOption;
  public chartHeight = '100%';
  public figureID = '';
  private chartInstance: ECharts;

  constructor(
    private download: DownloadService,
    private fileSaver: FileSaverService,
    private modal: NzModalService,
    public ui: UiService
  ) { }

  public ngOnInit(): void {
    if (this.chart?.table?.data) {
      this.subscription = this.activeFilters$.subscribe((filters: Filters): void => {

        // Assign id to all charts in figure panel
        this.figureID = this.small ? `fig-${this.chart.chartNumber}` : '';
        if (this.chart.table.headers?.chartHeight) {
          this.chartHeight = this.chart.table.headers.chartHeight;
        }

        this.options = getChart(this.chart, filters);
      });
    }
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // Called once echart instance is initialized
  public chartInit(chart: ECharts): void {
    this.chartLoading.emit(true);
    this.chartInstance = chart;
  }

  // Called when chart animation is done
  public chartFinished(): void {
    if (
      this.chartInstance &&         // Safety check
      this.chartInstance.getWidth() // Avoid capturing chart when it's in a hidden tab (eagerly-loaded)
    ) {
      // Capture chart canvas to render as an <img>
      this.src = this.chartInstance.getDataURL({ pixelRatio: 2, backgroundColor: 'white' });
    }
    this.chartLoading.emit(false);
  }

  public downloadPNG(): void {
    this.fileSaver.saveAs(this.src, `${this.chart.title}.png`);
  }

  public downloadFigureXLSX(): void {

    if (this.chart?.table) {

      const data = [];
      const headers = this.chart.table.headers?.downloadHeaders;

      // note: this functions could be moved to the other switch loop in ngOnInit, but since the data needs to be
      // calculated only in case the user clicks on download, they are here in a separated function than runs on demand
      switch (this.chart.table.headers?.chartType) {
        case 'pie':
          this.chart.table.data.forEach((element: { name: string, value: number }): void => {
            data.push({
              [headers[0]]: element.name,
              [headers[1]]: element.value
            });
          });
          break;

        case 'multiPie':
          const authors = Object.keys(this.chart.table.data);
          authors.forEach((author: string): void => {
            const row = {};
            row[headers[0]] = author;
            this.chart.table.data[author].forEach((element: { name: string, value: string }): void => {
              row[element.name] = element.value ? Number(element.value) : '';
            });
            data.push(row);
          });
          break;

        case 'bar':
          const groups = Object.keys(this.chart.table.data);
          groups.forEach((group: string): void => {
            data.push({ [headers[0]]: group, [headers[1]]: '' }); // empty row for the group title
            this.chart.table.data[group].forEach((element: { xAxisLabel: string, value: string }): void => {
              data.push({
                [headers[0]]: element.xAxisLabel,
                [headers[1]]: Number(element.value)
              });
            });
          });
          break;

        case 'stackedBar':
          this.chart.table.data.forEach((element: { [key in string]: string }): void => {
            const row = {};
            headers.forEach((header: string): void => {
              row[header] = element[header] ? element[header] : '';
            });
            data.push(row);
          });
          break;

        case 'line':
          const lineData = {};
          this.chart.table.data.forEach((serie: { name: string, data: number[] }): void => {
            lineData[serie.name] = serie.data;
          });
          const ageGroups = this.chart.table.headers.xAxisValue;
          ageGroups.forEach((ageGroup: string, index: number): void => {
            data.push({
              [headers[0]]: ageGroup,
              [headers[1]]: lineData[headers[1]][index],
              [headers[2]]: lineData[headers[2]][index]
            });
          });
          break;

        case 'forest':
          const forestGroups = Object.keys(this.chart.table.data);
          forestGroups.forEach((group: string): void => {
            data.push({ [headers[0]]: group, [headers[1]]: '' }); // empty row for the group title
            this.chart.table.data[group].forEach((element: { [key in string]: string }): void => {
              const row = {};
              headers.forEach((header: string): void => {
                row[header] = element[header] ? element[header] : '';
              });
              data.push(row);
            });
          });
          break;

        case 'graph':
          this.chart.table.data.links.forEach((link: { source: string, target: string, value: string }): void => {
            data.push({
              source: link.source,
              target: link.target,
              value: Number(link.value)
            });
          });
          break;
      }

      this.download.downloadXLSX(this.chart.table?.name, headers, data);

    }

  }

  public showModal(chart: Partial<Chart>): void {
    const title = 'Figure ' + chart.number + ': ' + chart.title;
    this.chartModal = chart;

    this.modal.create({
      nzTitle: title,
      nzContent: this.modalFullSizeView,
      nzFooter: null,
      nzStyle: {
        top: '30px',
        width: '100%'
      },
      nzBodyStyle: {
        width: '100%',
        height: '100%'
      }
    });
  }

}
