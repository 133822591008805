import { AuthService } from './../auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

type LoginScreen = 'signIn' | 'resetRequest' | 'newPassword' | 'thanks';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public requestForm: FormGroup;
  public newPasswordForm: FormGroup;

  public screen: LoginScreen;
  public resetCode: string;
  public buttonLoading: boolean;

  public loadingSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private route: ActivatedRoute
  ) { }

  public ngOnInit(): void {

    this.loginForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true]
    });

    this.requestForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
    }, { updateOn: 'submit' });

    this.newPasswordForm = this.fb.group({
      password: [null, [Validators.required]],
      confirmPassword: [null, [this.fieldsMatchValidator('password')]],
    }, { updateOn: 'submit' });

    this.loadingSubscription = this.auth.loading$.subscribe((value: boolean): void => {
     this.buttonLoading = value;
    });

    this.route.data.pipe(
      take(1)
    ).subscribe((data: { screen: LoginScreen }): void => {
       this.screen = (data && data.screen) ? data.screen : 'signIn';
      }
    );

    this.route.queryParamMap.pipe(
      take(1)
    ).subscribe((queryParams: Params): void => {
      if (queryParams && queryParams.params && queryParams.params.code) {
        this.resetCode = queryParams.params.code;
      }
    });
  }

  public ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }

  public fieldsMatchValidator(compareTo: string): ValidatorFn {
    return (control: AbstractControl): { [s: string]: boolean } => {
      if (!control.value) {
        return { error: true, required: true };
      } else if (control.value !== this.newPasswordForm.controls[compareTo].value) {
        return { match: true, error: true };
      }
      return {};
    };
  }

  public submitLoginForm(): void {
    this.auth.login(
      this.loginForm.controls.userName.value, this.loginForm.controls.password.value, this.loginForm.controls.remember.value);
  }

  public requestPasswordResetEmail(): void {
    if (this.validateForm(this.requestForm)) {
      this.auth.requestPasswordResetEmail(this.requestForm.controls.email.value);
    }
  }

  public resetPassword(): void {
    if (this.validateForm(this.newPasswordForm)) {
      this.auth.setNewPassword(
        this.newPasswordForm.controls.password.value, this.newPasswordForm.controls.confirmPassword.value, this.resetCode);
    }
  }

  private validateForm = (form: FormGroup): boolean => {
    for (const i in form.controls) {
      if (form.controls.hasOwnProperty(i)) {
        form.controls[i].markAsDirty();
        form.controls[i].updateValueAndValidity();
      }
    }
    return form.valid;
  }

}
