import { Injectable } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  public loadingState = 0;
  public filtersStatus = 'out';

  // Selected tab
  private selectedTabSource = new BehaviorSubject<number>(0);
  public selectedTab$ = this.selectedTabSource.asObservable();

  // Loaded tab (selected tab after content is loaded)
  private currentTabInViewSource = new Subject<number>();
  private currentTabInView$ = this.currentTabInViewSource.asObservable();

  constructor(
    private matomoTracker: MatomoTracker,
  ) { }

  public tabIsInView(tabIndex: number): void {
    this.currentTabInViewSource.next(tabIndex);
  }

  public toggleFilters(): void {
    this.filtersStatus = this.filtersStatus === 'in' ? 'out' : 'in';
    this.matomoTracker.trackEvent('ui', 'toggle filters');
  }

  public updateCurrentTab(tab: number): void {
    this.selectedTabSource.next(tab);
    this.matomoTracker.trackEvent('ui', 'tab change');
  }

  public updateLoadingState(loading: boolean): void {
    if (loading) {
      this.loadingState++;
      // Prevent filter affecting loading state calculation
    } else if (this.loadingState > 0 && !loading) {
      this.loadingState--;
    }
  }

  public onMarkdownClick(e: MouseEvent): void {
    const mapToTabIndex = {
      tab: 0,
      fig: 1,
      ref: 2,
      sup: 3
    };

    const scrollIntoView = (id: string): void => {
      const target = document.getElementById(id);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    };

    const el = e.target as HTMLElement;
    const fragment = el.getAttribute && el.getAttribute('class');
    const tabCategory = fragment && fragment.slice(0, 3);

    // Check if we are on the right tab
    if (mapToTabIndex.hasOwnProperty(tabCategory)) {
      const currentTabIndex = this.selectedTabSource.getValue();
      const requiredTabIndex = mapToTabIndex[tabCategory];

      // Right tab
      if (currentTabIndex === requiredTabIndex) {
        scrollIntoView(fragment);
      } else {

        // Wrong tab, so change it before scroll
        this.updateCurrentTab(requiredTabIndex);
        this.currentTabInView$.pipe(
          take(1)
        ).subscribe((tabInView: number): void => {
          if (tabInView === requiredTabIndex) {
            // Timeout is to allow angular to render chart images
            setTimeout((): void =>
              scrollIntoView(fragment)
              , 0);
          }
        });
      }
    }
    this.matomoTracker.trackEvent('ui', 'markdown url click');
  }
}
