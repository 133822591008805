<div fxLayout="row" fxLayoutAlign="start start" class="menu-container" fxFlexFill>

    <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="1 1 100%" *ngIf="toc$ | async as report">
        <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: report }"></ng-container>

        <ng-template #menuTpl let-content>
            <!-- <ng-container *ngIf="selectedSectionNumber$ | async as selectedSectionNumber">
                <ng-container *ngIf="ui.currentSectionInView$ | async as currentSectionFragment"> -->
            <ng-container *ngFor="let contentEntry of content">

                <ng-container *ngIf="getSectionDepth(contentEntry) !== menuMaxDepth else childTpl">

                    <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="0 0 auto">

                        <div fxLayout="row" fxLayoutAlign="space-between center" (click)="select(contentEntry)"
                            class="parent-menu-item"
                            [class.active]="(selectedSectionNumber$ | async) === getSectionNumber(contentEntry)">
                            <div class="label"
                                [class.expanded]="(selectedRootSectionNumber$ | async)  === contentEntry.order1">
                                {{contentEntry.title}}</div>
                            <div class="icon"><i nz-icon
                                    [nzType]="(selectedRootSectionNumber$ | async)  === contentEntry.order1 && contentEntry.sections && contentEntry.sections.length ? 'down' : 'right' "
                                    nzTheme="outline"></i></div>
                        </div>

                        <div fxLayout="column" *ngIf="(selectedRootSectionNumber$ | async)  === contentEntry.order1"
                            fxFlex="100%">
                            <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: contentEntry.sections }">
                            </ng-container>
                        </div>

                    </div>

                </ng-container> <!-- End of parent template -->

                <ng-template #childTpl>
                    <div class="child-menu-item"
                        [class.active]="(selectedSectionNumber$ | async) === getSectionNumber(contentEntry)"
                        (click)="select(contentEntry)">
                        <div class="label">{{contentEntry.title}}</div>
                    </div>
                </ng-template> <!-- End of child template -->

            </ng-container> <!-- End of ngFor -->
        </ng-template>

    </div>

</div>