import { Component, OnInit, Input } from '@angular/core';
import { Supplementary, UploadFile } from '../graphql/graphql';
import { FileSaverService } from '../file-saver.service';


@Component({
  selector: 'app-supplementary',
  templateUrl: './supplementary.component.html',
  styleUrls: ['./supplementary.component.scss']
})
export class SupplementaryComponent implements OnInit {
  @Input() public supplementary: Supplementary;

  constructor(public fileSaver: FileSaverService) { }

  public ngOnInit(): void {}

  public downloadFile(resource: UploadFile): void {
    const filePath = resource.url;
    this.fileSaver.saveAs(filePath, resource.name + resource.ext);
  }

}
