import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileSaverService {

  constructor() { }

  // tslint:disable-next-line: deprecation
  public saveAs = saveAs;
}
