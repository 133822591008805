import { CreateReportGQL, DeleteReportGQL, UpdateReportGQL, ReportQuery, ReportGQL } from './../graphql/graphql';
import { BehaviorSubject, combineLatest, Observable, merge, ReplaySubject, Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UiService } from '../ui.service';
import { getSectionNumber, enumerate } from '../helpers';
import {
  SectionsQuery,
  FiltersGQL,
  SectionsGQL,
  FiltersQuery,
  CreateReportMutation,
  CreateReportMutationVariables,
  ReportsDocument,
  EditReportInput,
  UpdateReportMutation,
  ReportDocument,
  DeleteReportMutation,
} from '../graphql/graphql';
import { map, shareReplay, switchMap, distinctUntilChanged, filter, withLatestFrom, take } from 'rxjs/operators';
import { ApolloQueryResult } from 'apollo-client';
import { SelectedFilters, Filters, FilterRow, NumberedSection, NumberedReference, ValueOf, FiltersCategories } from '../types';
import { FetchResult } from 'apollo-link';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy {
  public showModal = false;
  public modalSaving = false;
  public reportName = '';
  private subscriptions: Subscription[];

  constructor(
    public ui: UiService,
    private router: Router,
    private reportGQL: ReportGQL,
    private filtersGQL: FiltersGQL,
    private sections: SectionsGQL,
    private route: ActivatedRoute,
    private createReportGQL: CreateReportGQL,
    private updateReportGQL: UpdateReportGQL,
    private deleteReportGQL: DeleteReportGQL,
    private message: NzMessageService,

  ) { }

  public reportId$ = this.route.params.pipe(
    map((params: Params): string =>
      params.reportId)
  );

  // All available filters return by backend
  public allFilters$ = this.filtersGQL.watch().valueChanges.pipe(
    map(({ data }: ApolloQueryResult<FiltersQuery>): FiltersQuery => data)
  );

  // Filters apply trigger
  private applyFiltersSource = new BehaviorSubject<boolean>(true);
  private applyFilters$ = this.applyFiltersSource.asObservable();

  // Master report mode has no id
  public noMaster$ = this.reportId$.pipe(
    map((reportId: string): boolean => !!reportId)
  );

  public selectedFiltersSource = new ReplaySubject<SelectedFilters>(1);
  public selectedFilters$ = this.selectedFiltersSource.asObservable();

  private appliedFilters$ = this.applyFilters$.pipe(
    withLatestFrom(this.selectedFilters$),
    map(([_, selectedFilters]: [boolean, SelectedFilters]): SelectedFilters => selectedFilters),
  );

  // Custom report
  public customReport$ = this.reportId$.pipe(
    filter((value: string): boolean => !!value),
    switchMap((id: string): Observable<ReportQuery> =>
      this.reportGQL.watch({ id }).valueChanges.pipe(
        map(({ data }: ApolloQueryResult<ReportQuery>): ReportQuery => data)
      )
    )
  );

  // Preset filters from the custom report
  private customReportFilters$ = this.customReport$.pipe(
    map((report: ReportQuery): Filters => {
      const filters: Filters = {
        // TODO refactor
        objectives: report.report.objectives.map(
          (entry: ReportQuery['report']['objectives'][0]): Filters['objectives'][0] =>
            ({ name: entry.name, id: entry.id, value: true })
        ),
        countries: report.report.countries.map(
          (entry: ReportQuery['report']['countries'][0]): Filters['countries'][0] =>
            ({ name: entry.name, id: entry.id, value: true, partOf: entry.partOf || '' })
        ),
        settings: report.report.settings.map(
          (entry: ReportQuery['report']['settings'][0]): Filters['settings'][0] =>
            ({ name: entry.name, id: entry.id, value: true })
        )
      };

      return filters;
    }),
    // tap((): void => this.applyFilters()),
    shareReplay()
  );

  // All filters with applied user changes
  public filters$ =
    combineLatest([
      this.allFilters$,
      this.appliedFilters$,
      this.noMaster$
    ])
      .pipe(
        map(([allFilters, selectedFilters, noMaster]: [FiltersQuery, SelectedFilters, boolean]): Filters => {
          const filters: Filters = {};
          // If its MasterReport edit mode, set all filters as true
          if (!noMaster && !Object.keys(selectedFilters).length) {
            for (const filterGroupName in allFilters) {
              if (allFilters.hasOwnProperty(filterGroupName)) {
                const groupFilters = allFilters[filterGroupName] as (FiltersQuery['objectives'] | FiltersQuery['countries'] | FiltersQuery['settings']);
                if (!selectedFilters.hasOwnProperty(filterGroupName)) {
                  selectedFilters[filterGroupName] = [];
                }
                // tslint:disable-next-line: max-line-length
                groupFilters.forEach((filterGroup: (FiltersQuery['objectives'] | FiltersQuery['countries'] | FiltersQuery['settings'])[0]): void => {
                  const filterName = filterGroup.name;
                  selectedFilters[filterGroupName].push(filterName);
                });
              }
            }
          }
          // countries and continents
          if (allFilters.hasOwnProperty('countries')) {
            const countryFilters = allFilters.countries as (FiltersQuery['countries']);
            if (!filters.hasOwnProperty('countries')) {
              filters.countries = [];
            }
            countryFilters.forEach((filterGroup: FiltersQuery['countries'][0]): void => {
              const filterName = filterGroup.name;
              const continent = filterGroup.partOf;
              filters.countries.push({
                name: filterName,
                value: (selectedFilters.countries && (selectedFilters.countries.includes(filterName) ||
                  selectedFilters.countries.includes(continent))),
                id: filterGroup.id,
                partOf: filterGroup.partOf
              });
            });
          }
          // rest of the filters
          for (const filterGroupName in allFilters) {
            if (allFilters.hasOwnProperty(filterGroupName) && filterGroupName !== 'countries') {
              const groupFilters = allFilters[filterGroupName] as (FiltersQuery['objectives'] | FiltersQuery['settings']);
              if (!filters.hasOwnProperty(filterGroupName)) {
                filters[filterGroupName] = [];
              }
              // tslint:disable-next-line: max-line-length
              groupFilters.forEach((filterGroup: (FiltersQuery['objectives'] | FiltersQuery['countries'] | FiltersQuery['settings'])[0]): void => {
                const filterName = filterGroup.name;
                filters[filterGroupName].push({
                  name: filterName,
                  value: (selectedFilters[filterGroupName] && selectedFilters[filterGroupName].includes(filterName)),
                  id: filterGroup.id,
                });
              });
            }
          }
          return filters;
        }),
        shareReplay(1)
      );

  // Master report with filters applied
  public filteredReport$ = this.filters$.pipe(
    switchMap((filters: Filters): Observable<ApolloQueryResult<SectionsQuery>> =>
      this.sections.watch({
        objectives: filters.objectives ?
          filters.objectives.filter((val: FilterRow): boolean => val.value).map((val: FilterRow): string => val.id) : [],
        settings: filters.settings ?
          filters.settings.filter((val: FilterRow): boolean => val.value).map((val: FilterRow): string => val.id) : [],
        countries: filters.countries ?
          filters.countries.filter((val: FilterRow): boolean => val.value).map((val: FilterRow): string => val.id) : []
      }).valueChanges
    ),
    map((result: ApolloQueryResult<SectionsQuery>): SectionsQuery['sections'] => result.data.sections),
    map((sections: SectionsQuery['sections']): NumberedSection[] => enumerate(sections)),
    shareReplay(1)
  );

  private currentSectionInView$ = this.route.fragment.pipe(
    map((currentSection: string): string => currentSection || '1')
  );

  private currentRootSectionNumber$ = this.currentSectionInView$.pipe(
    map((sectionInView: string): number => {
      try {
        const [order1] = sectionInView.split('.');
        return parseInt(order1, 10);
      } catch (error) { }
      return 0;
    }),
    filter((rootSectionNumber: number): boolean => !!rootSectionNumber),
    distinctUntilChanged()
  );

  // Filtered report containing only selected section
  public report$ = combineLatest([
    this.filteredReport$,
    this.currentRootSectionNumber$
  ]).pipe(
    map(([sections, selectedSection]: [NumberedSection[], number]): NumberedSection[] =>
      sections.filter((section: NumberedSection): boolean =>
        section.order1 === selectedSection
      )
    ),
    shareReplay()
  );

  public references$ = this.filteredReport$.pipe(
    map((report: NumberedSection[]): Partial<NumberedReference>[] => {
      const references: Partial<NumberedReference>[] = [];
      if (report) {
        report.forEach((section: NumberedSection): void => {
          if (section.references && section.references.length > 0) {
            section.references.forEach((reference: NumberedReference): void => {
              references.push(reference);
            });
          }
        });
      }
      const uniqueReferences = [...new Map(references.map((reference: Partial<NumberedReference>): [string, Partial<NumberedReference>] =>
        [reference.id, reference])).values()]
        .sort((a: Partial<NumberedReference>, b: Partial<NumberedReference>): number => a.referenceNumber - b.referenceNumber);

      return uniqueReferences;
    }),
    shareReplay()
  );

  public loadingData$ = merge(
    this.filters$.pipe(map((): true => true)),
    this.report$.pipe(map((): false => false)),
  );


  public ngOnInit(): void {
    this.subscriptions = ([
      this.customReportFilters$.subscribe((filters: Filters): void => {
        const selectedFilters: SelectedFilters = {};
        const groupCategories: FiltersCategories = Object.keys(filters) as FiltersCategories;
        groupCategories.forEach((categoryName: 'objectives' | 'countries' | 'settings'): void => {
          const categoryArr = filters[categoryName];
          categoryArr.forEach((row: FilterRow): void => {
            if (!selectedFilters[categoryName]) {
              selectedFilters[categoryName] = [];
            }
            if (!row.value) {
              const selectedElementIndex = selectedFilters[categoryName].indexOf(row.name);
              if (selectedElementIndex > -1) {
                selectedFilters[categoryName].splice(selectedElementIndex, 1);
              }
            } else if (selectedFilters[categoryName].indexOf(row.name) === -1) {
              selectedFilters[categoryName].push(row.name);
            }
          });
        });
        this.selectedFiltersSource.next(selectedFilters);
        this.applyFiltersSource.next(true);
      }),
      this.noMaster$.subscribe((noMaster: boolean): void => {
        if (!noMaster) {
          this.selectedFiltersSource.next({});
          this.applyFiltersSource.next(true);
        }
      })
    ]);
  }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    }
  }

  public applyFilters(): void {
    setTimeout((): void => {
      this.applyFiltersSource.next(true);
    }, 0);
  }

  public updateCurrentSection(section: SectionsQuery['sections'][0]): void {
    const fragment = getSectionNumber(section);
    this.router.navigate([], { fragment });
  }

  // reusable logic for actions
  private convertFilters(filters: Filters): EditReportInput | Partial<CreateReportMutationVariables> {
    const activeFilters: EditReportInput | Partial<CreateReportMutationVariables> = {};
    for (const filterName in filters) {
      if (filters.hasOwnProperty(filterName)) {
        if (!activeFilters[filterName]) {
          activeFilters[filterName] = [];
        }
        filters[filterName].forEach((value: (ValueOf<Filters>)[0]): void => {
          if (value.value) {
            activeFilters[filterName].push(value.id);
          }
        });
      }
    }
    return activeFilters;
  }

  public createReport(name: string):
    // tslint:disable-next-line: no-any
    Observable<FetchResult<CreateReportMutation, Record<string, any>, Record<string, any>>> {
    const filters$ = this.filters$.pipe(
      map((filters: Filters): Partial<CreateReportMutationVariables> => {
        return this.convertFilters(filters);
      })
    );

    return filters$.pipe(
      switchMap((filters: Partial<CreateReportMutationVariables>):
        // tslint:disable-next-line: no-any
        Observable<FetchResult<CreateReportMutation, Record<string, any>, Record<string, any>>> =>
        this.createReportGQL.mutate({
          name,
          ...filters,
        },
          {
            refetchQueries: [{ query: ReportsDocument }] // Update list of reports
          })
      ),
      take(1)
    );
  }

  public updateReport(reportId: string): Observable<FetchResult<UpdateReportMutation, Record<string, string>, Record<string, string>>> {
    const filters$ = this.filters$.pipe(
      map((filters: Filters): EditReportInput => {
        return this.convertFilters(filters);
      })
    );

    return filters$.pipe(
      switchMap((filters: EditReportInput):
        Observable<FetchResult<UpdateReportMutation, Record<string, string>, Record<string, string>>> =>
        this.updateReportGQL.mutate({
          id: reportId,
          data: filters,
        },
          {
            refetchQueries: [{ query: ReportsDocument }, { query: ReportDocument, variables: { id: reportId } }]
          })
      ),
      take(1)
    );
  }

  public deleteReport(id: string): Observable<FetchResult<DeleteReportMutation, Record<string, string>, Record<string, string>>> {
    return this.deleteReportGQL.mutate({
      id,
    },
      {
        refetchQueries: [{ query: ReportsDocument }] // Update list of reports
      });
  }

  public saveReport(): void {
    this.modalSaving = true;
    this.createReport(this.reportName)
      // tslint:disable-next-line: no-any
      .subscribe((res: FetchResult<CreateReportMutation, Record<string, any>, Record<string, any>>): void => {
        this.showModal = false;
        this.modalSaving = false;
        this.reportName = '';
        this.message.success(`Report successfully updated`);
        setTimeout((): Promise<boolean> => this.router.navigate(['list']), 300);
      },
        (err: ErrorEvent): void => {
          this.message.error(err.error ? err.error.message || err.error.err : err.message || err);
        });
  }

  public cancelSave(): void {
    this.showModal = false;
    this.reportName = '';
  }

  public update(reportId: string): void {
    this.updateReport(reportId).subscribe((res: FetchResult<UpdateReportMutation, Record<string, string>>): void => {
      this.router.navigate(['list']);
      this.message.success(`Report successfully updated`);
    },
      (err: ErrorEvent): void => {
        this.message.error(err.error ? err.error.message || err.error.err : err.message || err);
      }
    );
  }

  public cancelUpdate(): void {
    this.router.navigate(['list']);
  }

}
