import { ViewerComponent } from './viewer/viewer.component';
import { ReportListComponent } from './report-list/report-list.component';
import { EditorComponent } from './editor/editor.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './interceptor.service';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'reset',
    component: LoginComponent,
    data: { screen: 'newPassword' }
  },
  {
    path: 'emailSent',
    component: LoginComponent,
    data: { screen: 'thanks' }
  },
  {
    path: 'editor',
    component: EditorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'editor/:reportId',
    component: EditorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'clone/:reportId',
    component: EditorComponent,
    data: { clone: true },
    canActivate: [AuthGuard]
  },
  {
    path: 'list',
    component: ReportListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ':reportId',
    component: ViewerComponent
  },
  {
    path: '**',
    redirectTo: 'editor'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ]
})
export class AppRoutingModule { }
