import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { FileSaverService } from './file-saver.service';
import { TableRow, Worksheet, Row } from './types';
import { ReportQuery } from './graphql/graphql';
import { NzMessageService } from 'ng-zorro-antd/message';
import { MatomoTracker } from 'ngx-matomo';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  private downloadingSource = new BehaviorSubject<boolean>(false);
  public downloading$: Observable<boolean> = this.downloadingSource.asObservable();

  private excellentEndpoint = `https://api.tor.eviviz.com/v1/excel`;


  constructor(
    private http: HttpClient,
    private fileSaver: FileSaverService,
    private message: NzMessageService,
    private matomoTracker: MatomoTracker,
  ) { }


  public download(customReport$: Observable<ReportQuery>, format: 'pdf' | 'docx'): void {
    this.downloadingSource.next(true);

    customReport$.pipe(
      take(1)
    ).subscribe((data: ReportQuery): void => {
      if (data.report[format] !== null) {
        const filename = `BMS SLR Report.${format}`;
        const filePath = data.report[format].url;
        this.fileSaver.saveAs(filePath, filename);
      } else {
        this.message.error('Report is not ready yet. Please refresh the page and try again.');
      }
      this.downloadingSource.next(false);
    });
    this.matomoTracker.trackEvent('download', format);
  }


  public downloadXLSX = (name: string, headers: string[], tableRows: TableRow[]): void => {
    const worksheet: Worksheet = {
      name,
      headers: headers.map((header: string): Worksheet['headers'][0] =>
        ({
          key: header,
          header,
          width: 20
        })
      ),
      rows: [],
    };

    tableRows.forEach((tableRow: TableRow): void => {
      const excelRow: Row = {
        cells: {}
      };
      for (const column in tableRow) {
        if (tableRow.hasOwnProperty(column)) {
          const tableCell = tableRow[column];
          const cellValue = Array.isArray(tableCell) ? tableCell.join(', ') : tableCell;
          excelRow.cells[column] = {
            value: cellValue
          };
          excelRow.cells[column].style = {
            numFmt: '#,##0.00',
            alignment: {
              horizontal: 'left'
            }
          };
        }
      }
      worksheet.rows.push(excelRow);
    });

    this.http.post(this.excellentEndpoint,
      { worksheets: [worksheet] },
      { responseType: 'blob' },
    ).pipe(
      take(1),
    ).subscribe(
      (res: Blob): void => {
        this.fileSaver.saveAs(res, `${name}.xlsx`);
      }
    );

    this.matomoTracker.trackEvent('download', 'xlsx');
  }
}
