import { Component } from '@angular/core';
import { map, filter, switchMap, shareReplay } from 'rxjs/operators';
import { Params, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ReportQuery, SectionsQuery, ReportGQL, SectionsGQL } from '../graphql/graphql';
import { ApolloQueryResult } from 'apollo-client';
import { Filters, FilterRow, NumberedSection, NumberedReference } from '../types';
import { enumerate } from '../helpers';
import { DownloadService } from '../download.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
  animations: [
    trigger(
      'downloadMenu',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out', style({ opacity: 1 })),
          ]
        ),
        transition(
          ':leave',
          [
            animate('100ms ease-in', style({ opacity: 0 })),
          ]
        )
      ]
    )
  ]
})
export class ViewerComponent {
  public showDownloadMenu = false;

  constructor(
    private route: ActivatedRoute,
    private reportGQL: ReportGQL,
    private sections: SectionsGQL,
    public download: DownloadService,
  ) { }

  public reportId$ = this.route.params.pipe(
    map((params: Params): string =>
      params.reportId)
  );

  // Custom report
  private customReport$ = this.reportId$.pipe(
    filter((value: string): boolean => !!value),
    switchMap((id: string): Observable<ReportQuery> =>
      this.reportGQL.watch({ id }).valueChanges.pipe(
        map(({ data }: ApolloQueryResult<ReportQuery>): ReportQuery => data)
      )
    )
  );

  // Preset filters from the custom report
  public filters$ = this.customReport$.pipe(
    map((report: ReportQuery): Filters => {
      const filters: Filters = {
        // TODO refactor
        objectives: report.report.objectives.map(
          (entry: ReportQuery['report']['objectives'][0]): Filters['objectives'][0] =>
            ({ name: entry.name, id: entry.id, value: true })
        ),
        countries: report.report.countries.map(
          (entry: ReportQuery['report']['countries'][0]): Filters['countries'][0] =>
            ({ name: entry.name, id: entry.id, value: true, partOf: entry.partOf || '' })
        ),
        settings: report.report.settings.map(
          (entry: ReportQuery['report']['settings'][0]): Filters['settings'][0] =>
            ({ name: entry.name, id: entry.id, value: true })
        )
      };

      return filters;
    }),
    shareReplay()
  );

  // Master report with filters applied
  public report$ = this.filters$.pipe(
    switchMap((filters: Filters): Observable<ApolloQueryResult<SectionsQuery>> =>
      this.sections.watch({
        objectives: filters.objectives ?
          filters.objectives.filter((val: FilterRow): boolean => val.value).map((val: FilterRow): string => val.id) : [],
        settings: filters.settings ?
          filters.settings.filter((val: FilterRow): boolean => val.value).map((val: FilterRow): string => val.id) : [],
        countries: filters.countries ?
          filters.countries.filter((val: FilterRow): boolean => val.value).map((val: FilterRow): string => val.id) : []
      }).valueChanges
    ),
    map((result: ApolloQueryResult<SectionsQuery>): SectionsQuery['sections'] => result.data.sections),
    map((sections: SectionsQuery['sections']): NumberedSection[] => enumerate(sections)),
    shareReplay(1)
  );

  public references$ = this.report$.pipe(
    map((report: NumberedSection[]): Partial<NumberedReference>[] => {
      const references: Partial<NumberedReference>[] = [];
      if (report) {
        report.forEach((section: NumberedSection): void => {
          if (section.references && section.references.length > 0) {
            section.references.forEach((reference: NumberedReference): void => {
              references.push(reference);
            });
          }
        });
      }
      const uniqueReferences = [...new Map(references.map((reference: Partial<NumberedReference>): [string, Partial<NumberedReference>] =>
        [reference.id, reference])).values()]
        .sort((a: Partial<NumberedReference>, b: Partial<NumberedReference>): number => a.referenceNumber - b.referenceNumber);

      return uniqueReferences;
    }),
    shareReplay()
  );

  public downloadPDF(): void {
    this.download.download(this.customReport$, 'pdf');
  }

  public downloadWord(): void {
    this.download.download(this.customReport$, 'docx');
  }
}
