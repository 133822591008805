<div [ngSwitch]="screen" fxLayout="row" fxLayoutAlign="center center" fxFlexFill class="background">

<!-- Sign In -->
  <form *ngSwitchCase="'signIn'" fxLayout="column" fxFlex="0 0 350px" class="login-box" fxLayoutAlign="start stretch" fxLayoutGap="25px"
    nz-form [formGroup]="loginForm" (ngSubmit)="submitLoginForm()">

    <div fxLayoutAlign="center">
      <img style="height:40px" src="assets/logo-icon.png">
    </div>

    <div class="title" fxLayoutAlign="center">
      Sign in
    </div>
    <div fxFlex="0 0 auto" >
      <nz-form-item fxLayout="column" fxLayoutGap="5px">
        <div>Username</div>
        <nz-form-control>
          <nz-input-group nzPrefixIcon="user">
            <input formControlName="userName" nz-input autocomplete="new-password"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div fxFlex="0 0 auto" >
      <nz-form-item fxLayout="column" fxLayoutGap="5px">
        <div>Password</div>
        <nz-form-control>
          <nz-input-group nzPrefixIcon="lock">
            <input formControlName="password" nz-input type="password" autocomplete="new-password"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div fxFlex="0 0 auto">
      <nz-form-item>
        <nz-form-control>
          <div formControlName="remember" nz-checkbox>Remember</div>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center" fxLayoutGap=10px>
      <div fxFlex="1 1 100%">
        <button id="signInButton" fxFlex nz-button nzType="primary" [disabled]="!loginForm.valid">Submit</button>
      </div>
    </div>

    <div fxFlex="0 0 auto" class="forgot" fxLayoutAlign="end">
      <a (click)="screen='resetRequest'">Forgot password?</a>
    </div>
  </form>


<!-- Password request -->
<form *ngSwitchCase="'resetRequest'" fxLayout="column" fxFlex="0 0 350px" class="login-box" fxLayoutAlign="start stretch" fxLayoutGap="25px"
  nz-form [formGroup]="requestForm" (ngSubmit)="requestPasswordResetEmail()" (keydown.enter)="requestPasswordResetEmail()">

  <div class="title" fxLayoutAlign="center">
    Forgot your password?
  </div>

  <div fxFlex="0 0 auto" >
    Please enter your email address to reset your password.
  </div>

  <div fxFlex="0 0 auto">
    <nz-form-item fxLayout="column" fxLayoutGap="5px">
      <div>Email</div>
      <nz-form-control>
        <nz-input-group nzPrefixIcon="mail">
          <input formControlName="email" nz-input appValidation
            nz-tooltip nzTooltipTitle="Please enter a valid email" nzTooltipPlacement="right" [nzTooltipTrigger]="null"
            [nzVisible]="requestForm.controls.email.hasError('pattern')"/>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center" fxLayoutGap=10px>
    <div fxFlex="1 1 50%"><button id="requestResetButton" fxFlex nz-button nzType="primary" [nzLoading]="buttonLoading">Request</button></div>
    <div fxFlex="1 1 50%"><button fxFlex nz-button nzType="default" (click)="screen='signIn'">Cancel</button></div>
  </div>

</form>


<!-- New password entry -->
<form *ngSwitchCase="'newPassword'" fxLayout="column" fxFlex="0 0 350px" class="login-box" fxLayoutAlign="start stretch" fxLayoutGap="25px"
  nz-form [formGroup]="newPasswordForm" (ngSubmit)="resetPassword()">

  <div class="title" fxLayoutAlign="center">
    Password recovery
  </div>

  <div fxFlex="0 0 auto" >
    <nz-form-item fxLayout="column" fxLayoutGap="5px">
      <div>Password</div>
      <nz-form-control>
        <nz-input-group nzPrefixIcon="lock">
          <input formControlName="password" nz-input type="password"/>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div fxFlex="1 1 auto" >
    <nz-form-item fxLayout="column" fxLayoutGap="5px">
      <div>Repeat password</div>
      <nz-form-control>
        <nz-input-group nzPrefixIcon="lock">
          <input formControlName="confirmPassword" nz-input type="password" appValidation
            nz-tooltip nzTooltipTitle="Passwords don't match!" nzTooltipPlacement="right" [nzTooltipTrigger]="null"
            [nzVisible]="newPasswordForm.controls.confirmPassword.hasError('match')"/>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="center" fxLayoutGap=10px>
    <div fxFlex="1 1 50%"><button id="setPasswordButton" fxFlex nz-button nzType="primary">Submit</button></div>
    <div fxFlex="1 1 50%"><button fxFlex nz-button nzType="default" (click)="screen='signIn'">Cancel</button></div>
  </div>


</form>



<!-- Thank you -->
<form *ngSwitchCase="'thanks'" fxLayout="column" fxFlex="0 0 450px" class="login-box" fxLayoutAlign="start stretch" fxLayoutGap="25px"
  nz-form [formGroup]="newPasswordForm">

  <div class="title" fxLayoutAlign="center">
    Thank you!
  </div>

  <div fxFlex="0 0 auto" >
    <p>An email has been sent to the address you have provided.</p>
    <p>Please follow the link in the email to complete your password reset request.</p>
  </div>

</form>

</div>

