import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SelectedFilters, FilterRow, Filters } from '../types';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { FiltersGQL, FiltersQuery } from '../graphql/graphql';
import { ApolloQueryResult } from 'apollo-client';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  constructor(
    private filtersGQL: FiltersGQL,
  ) { }
  @Input() public selectedFilters$: Observable<SelectedFilters>;
  @Input() public activeFilters$: Observable<Filters>;
  @Input() public loadingData: boolean;

  @Output() public filtersUpdated = new EventEmitter<SelectedFilters>();
  @Output() public filtersApplied = new EventEmitter<null>();

  // All available filters return by backend
  public allFilters$ = this.filtersGQL.watch().valueChanges.pipe(
    map(({ data }: ApolloQueryResult<FiltersQuery>): FiltersQuery => data)
  );

  // when true it disables the apply filters button
  public filtersNotChanged$: Observable<boolean>;

  public ngOnInit(): void {
    this.filtersNotChanged$ = combineLatest([
      this.selectedFilters$,
      this.activeFilters$
    ]).pipe(
      map(([selectedFilters, appliedFilters]: [SelectedFilters, Filters]): boolean => {
        if (Object.keys(selectedFilters).length === 0) {
          return true;
        }
        const appliedCountries = appliedFilters.countries
          .filter((country: FilterRow): boolean => country.value)
          .map((country: FilterRow): string => country.name);
        const appliedObjectives = appliedFilters.objectives
          .filter((objective: FilterRow): boolean => objective.value)
          .map((objective: FilterRow): string => objective.name);
        const appliedSettings = appliedFilters.settings
          .filter((setting: FilterRow): boolean => setting.value)
          .map((setting: FilterRow): string => setting.name);

        const isEqual = (array1: string[], array2: string[]): boolean =>
          array1 && array2
          && array1.length === array2.length
          && array1.sort().every((value: string, index: number): boolean => value === array2.sort()[index]);

        const sameCountries = isEqual(appliedCountries, selectedFilters.countries);
        const sameObjectives = isEqual(appliedObjectives, selectedFilters.objectives);
        const sameSettings = isEqual(appliedSettings, selectedFilters.settings);

        return sameCountries && sameObjectives && sameSettings;
      }
      ));
  }

  public filterChange(
    selectedFilters: SelectedFilters,
    groupName: keyof SelectedFilters,
    value: string[],
    filters: Partial<FilterRow>[]
  ): void {
    const allFilters = filters.map((filter: Partial<FilterRow>): string => filter.name);
    allFilters
      .filter((filter: string): boolean => value.indexOf(filter) === -1)
      .forEach((name: string): void => this.setFilter(selectedFilters, groupName, name, false));

    value.forEach((name: string): void => this.setFilter(selectedFilters, groupName, name, true));

    this.filtersUpdated.emit(selectedFilters);
  }

  public filterIsVisible(filter: keyof SelectedFilters, selectedFilters: SelectedFilters): boolean {
    // Always show objectives
    if (filter === 'objectives') {
      return true;
    }
    // Show countries only if Treatment patterns or Epidemiology is selected
    if (filter === 'countries') {
      return selectedFilters.objectives && selectedFilters.objectives.some((objective: string): boolean =>
        ['Treatment patterns', 'Epidemiology'].includes(objective)
      );
    }
    // Show settings only if Efficacy, Effectiveness or Surrogacy endpoints is selected
    if (filter === 'settings') {
      return selectedFilters.objectives && selectedFilters.objectives.some((objective: string): boolean =>
        ['Comparative efficacy', 'Comparative effectiveness', 'Surrogacy endpoints'].includes(objective)
      );
    }

    return true;
  }

  public setFilter(selectedFilters: SelectedFilters, group: keyof SelectedFilters, name: string, value: boolean): void {
    if (!selectedFilters[group]) {
      selectedFilters[group] = [];
    }

    if (!value) {
      const selectedElementIndex = selectedFilters[group].indexOf(name);
      if (selectedElementIndex > -1) {
        selectedFilters[group].splice(selectedElementIndex, 1);
      }
    } else if (selectedFilters[group].indexOf(name) === -1) {
      selectedFilters[group].push(name);
    }
  }

  public applyFilters(): void {
    this.filtersApplied.emit();
  }
}
