
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "Morph",
        "possibleTypes": [
          {
            "name": "UsersPermissionsMe"
          },
          {
            "name": "UsersPermissionsMeRole"
          },
          {
            "name": "UsersPermissionsLoginPayload"
          },
          {
            "name": "ForgotPassword"
          },
          {
            "name": "Abbreviation"
          },
          {
            "name": "AbbreviationConnection"
          },
          {
            "name": "AbbreviationAggregator"
          },
          {
            "name": "AbbreviationGroupBy"
          },
          {
            "name": "AbbreviationConnectionId"
          },
          {
            "name": "AbbreviationConnection_id"
          },
          {
            "name": "AbbreviationConnectionCreatedAt"
          },
          {
            "name": "AbbreviationConnectionUpdatedAt"
          },
          {
            "name": "AbbreviationConnectionAcronym"
          },
          {
            "name": "AbbreviationConnectionDescription"
          },
          {
            "name": "createAbbreviationPayload"
          },
          {
            "name": "updateAbbreviationPayload"
          },
          {
            "name": "deleteAbbreviationPayload"
          },
          {
            "name": "Chart"
          },
          {
            "name": "ChartConnection"
          },
          {
            "name": "ChartAggregator"
          },
          {
            "name": "ChartGroupBy"
          },
          {
            "name": "ChartConnectionId"
          },
          {
            "name": "ChartConnection_id"
          },
          {
            "name": "ChartConnectionCreatedAt"
          },
          {
            "name": "ChartConnectionUpdatedAt"
          },
          {
            "name": "ChartConnectionTitle"
          },
          {
            "name": "ChartConnectionTable"
          },
          {
            "name": "ChartConnectionNumber"
          },
          {
            "name": "createChartPayload"
          },
          {
            "name": "updateChartPayload"
          },
          {
            "name": "deleteChartPayload"
          },
          {
            "name": "Country"
          },
          {
            "name": "CountryConnection"
          },
          {
            "name": "CountryAggregator"
          },
          {
            "name": "CountryGroupBy"
          },
          {
            "name": "CountryConnectionId"
          },
          {
            "name": "CountryConnection_id"
          },
          {
            "name": "CountryConnectionCreatedAt"
          },
          {
            "name": "CountryConnectionUpdatedAt"
          },
          {
            "name": "CountryConnectionName"
          },
          {
            "name": "CountryConnectionPartOf"
          },
          {
            "name": "createCountryPayload"
          },
          {
            "name": "updateCountryPayload"
          },
          {
            "name": "deleteCountryPayload"
          },
          {
            "name": "Image"
          },
          {
            "name": "ImageConnection"
          },
          {
            "name": "ImageAggregator"
          },
          {
            "name": "ImageGroupBy"
          },
          {
            "name": "ImageConnectionId"
          },
          {
            "name": "ImageConnection_id"
          },
          {
            "name": "ImageConnectionCreatedAt"
          },
          {
            "name": "ImageConnectionUpdatedAt"
          },
          {
            "name": "ImageConnectionTitle"
          },
          {
            "name": "ImageConnectionImage"
          },
          {
            "name": "ImageConnectionSection"
          },
          {
            "name": "createImagePayload"
          },
          {
            "name": "updateImagePayload"
          },
          {
            "name": "deleteImagePayload"
          },
          {
            "name": "Job"
          },
          {
            "name": "JobConnection"
          },
          {
            "name": "JobAggregator"
          },
          {
            "name": "JobGroupBy"
          },
          {
            "name": "JobConnectionId"
          },
          {
            "name": "JobConnection_id"
          },
          {
            "name": "JobConnectionCreatedAt"
          },
          {
            "name": "JobConnectionUpdatedAt"
          },
          {
            "name": "JobConnectionReport"
          },
          {
            "name": "JobConnectionBaseUrl"
          },
          {
            "name": "createJobPayload"
          },
          {
            "name": "updateJobPayload"
          },
          {
            "name": "deleteJobPayload"
          },
          {
            "name": "MasterInfo"
          },
          {
            "name": "updateMasterInfoPayload"
          },
          {
            "name": "deleteMasterInfoPayload"
          },
          {
            "name": "Objective"
          },
          {
            "name": "ObjectiveConnection"
          },
          {
            "name": "ObjectiveAggregator"
          },
          {
            "name": "ObjectiveGroupBy"
          },
          {
            "name": "ObjectiveConnectionId"
          },
          {
            "name": "ObjectiveConnection_id"
          },
          {
            "name": "ObjectiveConnectionCreatedAt"
          },
          {
            "name": "ObjectiveConnectionUpdatedAt"
          },
          {
            "name": "ObjectiveConnectionName"
          },
          {
            "name": "createObjectivePayload"
          },
          {
            "name": "updateObjectivePayload"
          },
          {
            "name": "deleteObjectivePayload"
          },
          {
            "name": "Paragraph"
          },
          {
            "name": "ParagraphConnection"
          },
          {
            "name": "ParagraphAggregator"
          },
          {
            "name": "ParagraphGroupBy"
          },
          {
            "name": "ParagraphConnectionId"
          },
          {
            "name": "ParagraphConnection_id"
          },
          {
            "name": "ParagraphConnectionCreatedAt"
          },
          {
            "name": "ParagraphConnectionUpdatedAt"
          },
          {
            "name": "ParagraphConnectionTitle"
          },
          {
            "name": "ParagraphConnectionBody"
          },
          {
            "name": "createParagraphPayload"
          },
          {
            "name": "updateParagraphPayload"
          },
          {
            "name": "deleteParagraphPayload"
          },
          {
            "name": "Reference"
          },
          {
            "name": "ReferenceConnection"
          },
          {
            "name": "ReferenceAggregator"
          },
          {
            "name": "ReferenceAggregatorSum"
          },
          {
            "name": "ReferenceAggregatorAvg"
          },
          {
            "name": "ReferenceAggregatorMin"
          },
          {
            "name": "ReferenceAggregatorMax"
          },
          {
            "name": "ReferenceGroupBy"
          },
          {
            "name": "ReferenceConnectionId"
          },
          {
            "name": "ReferenceConnection_id"
          },
          {
            "name": "ReferenceConnectionCreatedAt"
          },
          {
            "name": "ReferenceConnectionUpdatedAt"
          },
          {
            "name": "ReferenceConnectionNumber"
          },
          {
            "name": "ReferenceConnectionDescription"
          },
          {
            "name": "ReferenceConnectionFullText"
          },
          {
            "name": "ReferenceConnectionLink"
          },
          {
            "name": "createReferencePayload"
          },
          {
            "name": "updateReferencePayload"
          },
          {
            "name": "deleteReferencePayload"
          },
          {
            "name": "Report"
          },
          {
            "name": "ReportConnection"
          },
          {
            "name": "ReportAggregator"
          },
          {
            "name": "ReportGroupBy"
          },
          {
            "name": "ReportConnectionId"
          },
          {
            "name": "ReportConnection_id"
          },
          {
            "name": "ReportConnectionCreatedAt"
          },
          {
            "name": "ReportConnectionUpdatedAt"
          },
          {
            "name": "ReportConnectionTitle"
          },
          {
            "name": "ReportConnectionAuthor"
          },
          {
            "name": "ReportConnectionPdf"
          },
          {
            "name": "ReportConnectionDocx"
          },
          {
            "name": "createReportPayload"
          },
          {
            "name": "updateReportPayload"
          },
          {
            "name": "deleteReportPayload"
          },
          {
            "name": "Section"
          },
          {
            "name": "SectionConnection"
          },
          {
            "name": "SectionAggregator"
          },
          {
            "name": "SectionAggregatorSum"
          },
          {
            "name": "SectionAggregatorAvg"
          },
          {
            "name": "SectionAggregatorMin"
          },
          {
            "name": "SectionAggregatorMax"
          },
          {
            "name": "SectionGroupBy"
          },
          {
            "name": "SectionConnectionId"
          },
          {
            "name": "SectionConnection_id"
          },
          {
            "name": "SectionConnectionCreatedAt"
          },
          {
            "name": "SectionConnectionUpdatedAt"
          },
          {
            "name": "SectionConnectionTitle"
          },
          {
            "name": "SectionConnectionOrder1"
          },
          {
            "name": "SectionConnectionOrder2"
          },
          {
            "name": "SectionConnectionOrder3"
          },
          {
            "name": "SectionConnectionOrder4"
          },
          {
            "name": "createSectionPayload"
          },
          {
            "name": "updateSectionPayload"
          },
          {
            "name": "deleteSectionPayload"
          },
          {
            "name": "Setting"
          },
          {
            "name": "SettingConnection"
          },
          {
            "name": "SettingAggregator"
          },
          {
            "name": "SettingGroupBy"
          },
          {
            "name": "SettingConnectionId"
          },
          {
            "name": "SettingConnection_id"
          },
          {
            "name": "SettingConnectionCreatedAt"
          },
          {
            "name": "SettingConnectionUpdatedAt"
          },
          {
            "name": "SettingConnectionName"
          },
          {
            "name": "createSettingPayload"
          },
          {
            "name": "updateSettingPayload"
          },
          {
            "name": "deleteSettingPayload"
          },
          {
            "name": "StyledTable"
          },
          {
            "name": "StyledTableConnection"
          },
          {
            "name": "StyledTableAggregator"
          },
          {
            "name": "StyledTableGroupBy"
          },
          {
            "name": "StyledTableConnectionId"
          },
          {
            "name": "StyledTableConnection_id"
          },
          {
            "name": "StyledTableConnectionCreatedAt"
          },
          {
            "name": "StyledTableConnectionUpdatedAt"
          },
          {
            "name": "StyledTableConnectionTitle"
          },
          {
            "name": "StyledTableConnectionContents"
          },
          {
            "name": "StyledTableConnectionFootnotes"
          },
          {
            "name": "StyledTableConnectionNumber"
          },
          {
            "name": "createStyledTablePayload"
          },
          {
            "name": "updateStyledTablePayload"
          },
          {
            "name": "deleteStyledTablePayload"
          },
          {
            "name": "Supplementary"
          },
          {
            "name": "SupplementaryConnection"
          },
          {
            "name": "SupplementaryAggregator"
          },
          {
            "name": "SupplementaryGroupBy"
          },
          {
            "name": "SupplementaryConnectionId"
          },
          {
            "name": "SupplementaryConnection_id"
          },
          {
            "name": "SupplementaryConnectionCreatedAt"
          },
          {
            "name": "SupplementaryConnectionUpdatedAt"
          },
          {
            "name": "SupplementaryConnectionName"
          },
          {
            "name": "createSupplementaryPayload"
          },
          {
            "name": "updateSupplementaryPayload"
          },
          {
            "name": "deleteSupplementaryPayload"
          },
          {
            "name": "Table"
          },
          {
            "name": "TableConnection"
          },
          {
            "name": "TableAggregator"
          },
          {
            "name": "TableGroupBy"
          },
          {
            "name": "TableConnectionId"
          },
          {
            "name": "TableConnection_id"
          },
          {
            "name": "TableConnectionCreatedAt"
          },
          {
            "name": "TableConnectionUpdatedAt"
          },
          {
            "name": "TableConnectionName"
          },
          {
            "name": "TableConnectionData"
          },
          {
            "name": "TableConnectionHeaders"
          },
          {
            "name": "TableConnectionGroups"
          },
          {
            "name": "TableConnectionFootnotes"
          },
          {
            "name": "TableConnectionFilterMap"
          },
          {
            "name": "TableConnectionSubgroups"
          },
          {
            "name": "TableConnectionNumber"
          },
          {
            "name": "createTablePayload"
          },
          {
            "name": "updateTablePayload"
          },
          {
            "name": "deleteTablePayload"
          },
          {
            "name": "UploadFile"
          },
          {
            "name": "UploadFileConnection"
          },
          {
            "name": "UploadFileAggregator"
          },
          {
            "name": "UploadFileAggregatorSum"
          },
          {
            "name": "UploadFileAggregatorAvg"
          },
          {
            "name": "UploadFileAggregatorMin"
          },
          {
            "name": "UploadFileAggregatorMax"
          },
          {
            "name": "UploadFileGroupBy"
          },
          {
            "name": "UploadFileConnectionId"
          },
          {
            "name": "UploadFileConnection_id"
          },
          {
            "name": "UploadFileConnectionCreatedAt"
          },
          {
            "name": "UploadFileConnectionUpdatedAt"
          },
          {
            "name": "UploadFileConnectionName"
          },
          {
            "name": "UploadFileConnectionAlternativeText"
          },
          {
            "name": "UploadFileConnectionCaption"
          },
          {
            "name": "UploadFileConnectionWidth"
          },
          {
            "name": "UploadFileConnectionHeight"
          },
          {
            "name": "UploadFileConnectionFormats"
          },
          {
            "name": "UploadFileConnectionHash"
          },
          {
            "name": "UploadFileConnectionExt"
          },
          {
            "name": "UploadFileConnectionMime"
          },
          {
            "name": "UploadFileConnectionSize"
          },
          {
            "name": "UploadFileConnectionUrl"
          },
          {
            "name": "UploadFileConnectionPreviewUrl"
          },
          {
            "name": "UploadFileConnectionProvider"
          },
          {
            "name": "UploadFileConnectionProvider_metadata"
          },
          {
            "name": "UsersPermissionsPermission"
          },
          {
            "name": "UsersPermissionsRole"
          },
          {
            "name": "UsersPermissionsRoleConnection"
          },
          {
            "name": "UsersPermissionsRoleAggregator"
          },
          {
            "name": "UsersPermissionsRoleGroupBy"
          },
          {
            "name": "UsersPermissionsRoleConnectionId"
          },
          {
            "name": "UsersPermissionsRoleConnection_id"
          },
          {
            "name": "UsersPermissionsRoleConnectionCreatedAt"
          },
          {
            "name": "UsersPermissionsRoleConnectionUpdatedAt"
          },
          {
            "name": "UsersPermissionsRoleConnectionName"
          },
          {
            "name": "UsersPermissionsRoleConnectionDescription"
          },
          {
            "name": "UsersPermissionsRoleConnectionType"
          },
          {
            "name": "createRolePayload"
          },
          {
            "name": "updateRolePayload"
          },
          {
            "name": "deleteRolePayload"
          },
          {
            "name": "UsersPermissionsUser"
          },
          {
            "name": "UsersPermissionsUserConnection"
          },
          {
            "name": "UsersPermissionsUserAggregator"
          },
          {
            "name": "UsersPermissionsUserGroupBy"
          },
          {
            "name": "UsersPermissionsUserConnectionId"
          },
          {
            "name": "UsersPermissionsUserConnection_id"
          },
          {
            "name": "UsersPermissionsUserConnectionCreatedAt"
          },
          {
            "name": "UsersPermissionsUserConnectionUpdatedAt"
          },
          {
            "name": "UsersPermissionsUserConnectionUsername"
          },
          {
            "name": "UsersPermissionsUserConnectionEmail"
          },
          {
            "name": "UsersPermissionsUserConnectionProvider"
          },
          {
            "name": "UsersPermissionsUserConnectionConfirmed"
          },
          {
            "name": "UsersPermissionsUserConnectionBlocked"
          },
          {
            "name": "UsersPermissionsUserConnectionRole"
          },
          {
            "name": "createUserPayload"
          },
          {
            "name": "updateUserPayload"
          },
          {
            "name": "deleteUserPayload"
          },
          {
            "name": "ComponentReportContentChart"
          },
          {
            "name": "ComponentReportContentImage"
          },
          {
            "name": "ComponentReportContentParagraph"
          },
          {
            "name": "ComponentReportContentReference"
          },
          {
            "name": "ComponentReportContentSection"
          },
          {
            "name": "ComponentReportContentStyledTable"
          },
          {
            "name": "ComponentReportContentTable"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SectionContentDynamicZone",
        "possibleTypes": [
          {
            "name": "ComponentReportContentChart"
          },
          {
            "name": "ComponentReportContentImage"
          },
          {
            "name": "ComponentReportContentParagraph"
          },
          {
            "name": "ComponentReportContentTable"
          },
          {
            "name": "ComponentReportContentStyledTable"
          }
        ]
      }
    ]
  }
};
      export default result;
    