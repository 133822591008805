import { Filters, NumberedSection, NumberedReference } from './../types';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { getSectionDepth, getSectionNumber } from '../helpers';
import { UiService } from '../ui.service';
import { SectionsQuery } from '../graphql/graphql';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})

export class ReportComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];


  private inViewSections: SectionsQuery['sections'] = [];
  private scrollTarget = '';
  public getSectionDepth = getSectionDepth;
  public getSectionNumber = getSectionNumber;

  @Input() public report$: Observable<NumberedSection[]>;
  @Input() public references$: Observable<Partial<NumberedReference>[]>;
  @Input() public activeFilters$: Observable<Filters>;
  @Input() public showActions = false;
  @Output() private currentSectionChange = new EventEmitter<SectionsQuery['sections'][0]>();

  constructor(
    private route: ActivatedRoute,
    public ui: UiService,
  ) { }

  public ngOnInit(): void {
    this.subscriptions = [
      this.route.fragment.subscribe((fragment: string): void => {
        // Avoid duplicate scolling to element user already scrolled to
        if (this.scrollTarget !== fragment) {
          this.scrollTarget = fragment ? fragment : '';
          const el = document.getElementById(fragment);
          if (el) {
            // note: smooth scrolling not supported in safari
            el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
        }
      })
    ];
    // Cleanup list of sections in view when report changes. Avoids constant jumping to #1
    this.report$.subscribe((): undefined[] => this.inViewSections = []);

  }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription: Subscription): void =>
        subscription.unsubscribe()
      );
    }
  }

  /**
   * Keeps track of the headers that are currently in the viewport, and updates the current fragment the top visible header.
   */
  public onInViewportChange(isInViewport: boolean, section: SectionsQuery['sections'][0]): void {
    // Ignore sections deeper than 2nd level
    if (getSectionDepth(section) < 3) {
      if (isInViewport) {
        this.inViewSections.push(section);
      } else {
        const index = this.inViewSections.indexOf(section);
        if (index !== -1) {
          this.inViewSections.splice(index, 1);
        }
      }

      if (this.inViewSections[0]) {
        // DESC sort by order
        this.inViewSections.sort((a: SectionsQuery['sections'][0], b: SectionsQuery['sections'][0]): number =>
          -((a.order1 - b.order1) * 1000 + (a.order2 - b.order2) * 100 + (a.order3 - b.order3) * 10 + (a.order4 - b.order4))
        );
        // Set scroll target to avoid duplicate scolling to element user just scrolled to
        this.scrollTarget = getSectionNumber(this.inViewSections[0]);
        this.currentSectionChange.emit(this.inViewSections[0]);
      }

    }
  }

}
