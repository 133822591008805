import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appValidation]'
})

export class ValidationDirective {

  constructor(
    private formControl: NgControl,
    private el: ElementRef) {}

  // clear validation and errors on focus (not content)
  @HostListener ('focus') public onFocus(): void {
    this.formControl.control.setErrors(null);
    this.formControl.control.markAsPristine();
    this.formControl.control.markAsUntouched();
  }

  // blur on enter
  @HostListener ('keydown.enter') public test(): void {
    this.el.nativeElement.blur();
  }

}
