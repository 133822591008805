<ng-container *ngIf="report$ | async as report; else reportLoadingSpinner">

    <div fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="center start" class="report">
        <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start stretch" class="container">
            <ng-container *ngFor="let rootItem of report">
                <ng-container *ngTemplateOutlet="sectionTemplate; context: {$implicit: rootItem}"></ng-container>
            </ng-container>

        </div>
    </div>

    <ng-template #sectionTemplate let-section>

        <ng-container *ngIf="getSectionDepth(section) as depth">
            <ng-container [ngSwitch]="depth">
                    <span [id]="getSectionNumber(section)" snInViewport [inViewportOptions]="{ rootMargin: '-120px 0px 0px 0px'}"
                        (inViewportChange)="onInViewportChange($event, section)">
                        <h2 *ngSwitchCase="1">{{section.sectionNumber}} {{section.title}}</h2>
                        <h3 *ngSwitchCase="2">{{section.sectionNumber}} {{section.title}}</h3>
                        <h4 *ngSwitchDefault>{{section.sectionNumber}} {{section.title}}</h4>
                    </span>
            </ng-container>
        </ng-container>

        <ng-container *ngFor="let contentItem of section.content">
            <ng-container [ngSwitch]="contentItem.__typename">

                <ng-container *ngSwitchCase="'ComponentReportContentSection'">
                    <ng-container *ngTemplateOutlet="sectionTemplate; context: {$implicit: contentItem.section}">
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'ComponentReportContentParagraph'">
                    <ng-container *ngTemplateOutlet="paragraphTemplate; context: {$implicit: contentItem.paragraph}">
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'ComponentReportContentChart'">
                    <app-chart [id]="contentItem.chart.title" [chart]="contentItem.chart" [activeFilters$]="activeFilters$" [showActions]="showActions"></app-chart>
                </ng-container>

                <ng-container *ngSwitchCase="'ComponentReportContentTable'">
                    <app-table [id]="contentItem.table.name" [table]="contentItem.table" [activeFilters$]="activeFilters$"></app-table>
                </ng-container>

                <ng-container *ngSwitchCase="'ComponentReportContentStyledTable'">
                    <app-styled-table [styledTable]="contentItem.styled_table"></app-styled-table>
                </ng-container>

                <ng-container *ngSwitchCase="'ComponentReportContentImage'">
                    <app-image [image]="contentItem.image" [showActions]="showActions"></app-image>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <p>Unknown content type: {{contentItem.__typename}} </p>
                </ng-container>

            </ng-container>
        </ng-container>

        <ng-container *ngIf="section.title === 'References' && references$ | async as references">
            <ng-container *ngIf="references">
                <span [id]="getSectionNumber(section)" snInViewport [inViewportOptions]="{ rootMargin: '-120px 0px 0px 0px'}"
                (inViewportChange)="onInViewportChange($event, section)">
                </span>
                <ng-container *ngFor="let reference of references">
                    <app-reference [reference]="reference" [small]="false"></app-reference>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-template>

    <ng-template #paragraphTemplate let-paragraph>
        <ng-container *ngIf="paragraph">
            <markdown [data]="paragraph.body" (click)="ui.onMarkdownClick($event);"></markdown>
        </ng-container>
    </ng-template>

</ng-container>

<ng-template #reportLoadingSpinner let-references>
    <div fxFlexFill fxLayout="column" fxLayoutAlign="center center" fxLayoutGap=40px>
        <h2>Loading report data, please wait.</h2>
        <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
    </div>
</ng-template>
