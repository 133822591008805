import { Component, OnInit, Input, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Image } from './../graphql/graphql';
import { FileSaverService } from '../file-saver.service';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input() public image: Image;
  @Input() public small = false;
  @Input() public noModal = true;
  @Input() public showActions: boolean;
  @ViewChild('modalFullSizeView', { static: true }) private modalFullSizeView: TemplateRef<{}>;
  @Output() public chartLoading: EventEmitter<boolean> = new EventEmitter();

  public imageID = '';
  public environment = environment;

  constructor(
    public fileSaver: FileSaverService,
    private modal: NzModalService,
  ) { }

  public ngOnInit(): void {
    if (this.image?.title) {
      const strippedNumber = this.image.title.substring(
        this.image.title.indexOf(' ') + 1,
        this.image.title.indexOf(':')
      );
      this.imageID = `fig-${strippedNumber}`;
    }
  }

  public downloadPNG(src: string): void {
    // https://github.com/eligrey/FileSaver.js/issues/557
    fetch(src, { mode: 'no-cors' })
        .then((res: Response): Promise<Blob> => res.blob())
        .then((blob: Blob): void => {
          this.fileSaver.saveAs(blob, `${this.image.title}.png`);
        });
  }

  public showModal(image: Partial<Image>): void {
    this.noModal = false;
    const title = image.title;

    this.modal.create({
      nzTitle: title,
      nzContent: this.modalFullSizeView,
      nzFooter: null,
      nzStyle: {
        top: '30px',
        width: '100%'
      },
      nzBodyStyle: {
        width: '100%',
        height: '100%'
      },
      nzOnCancel: (): boolean => this.noModal = true
    });
  }

}
